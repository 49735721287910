import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 20px 20px 20px 20px;
	cursor: pointer;
`;

export const Tag = styled.div`
	border: ${({color}) => `1px solid ${color}`};
	color: ${({color}) => color};
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 10px;
	padding: 8px 22px;
	border-radius: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
`;

export const Title = styled.div`
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
	color : #323643;
`;

export const Desc = styled.div`
	font-size: 12px;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	-webkit-box-orient: vertical;
	height: 10%;
	word-break: break-word;
	width:90%
`;

export const Image = styled.img`
	height: 100%;
	overflow: hidden;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
`;

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 179px;
	border-radius: 10px;
	margin-top: 10px;
`;

export const PlaceHolderImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 189px;
	border-radius: 20px;
	margin-top: 10px;
	background-color: #f7f8fb;
`;

export const Delete = styled.button`
	background-color: #f7f8fb;
	width: 40px;
	height: 40px;
	border: 0;
	outline: 0;
	border-radius: 50%;
	margin: 0px 5px;
	cursor: pointer;
`;

export const Loader = styled.div`
	> div {
		width: 30px;
		height: 30px;
	}
`;

export const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;

export const Profile = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 50%;
`;

export const CreatedAtContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

export const Name = styled.div`
	font-size: 12px;
	text-transform: capitalize;
	margin-bottom: 5px;
	font-weight:400;
`;

export const Date = styled.div`
	color: #989aa1;
	font-size: 12px;
	line-height: 16px;
`;

export const EditImg = styled.img`
	width:40px;
	object-fit:contain;`;

export const DeleteImg = styled.img`
	width: 40px;
	object-fit: contain;
`;

export const ImageSetting =  styled.div`
	position:relative;
`;

export const ImageCountBox = styled.div`
position: absolute;
    right: 10px;
    top: 68%;
    border: 1px solid cornsilk;
    border-radius: 20px;
    width: 50px;
    display: flex;
    justify-content: center;
	font-size: 24px;
    height: 50px;
    padding: 7px;
    color: #F4F5F7;
`