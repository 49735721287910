import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

export const UsersListContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 23px;
`;

export const TableHeader = styled.div`
	display: flex;
	flex-direction: row;
	oveflow: hidden;
	padding: 22px;
	cursor: pointer;
	opacity: 0.85;
	transition: 0.3s;
	align-items: center;
	&:hover {
		opacity: 1;
		background-color: #e9e9e9;
	}
`;

export const Flex03 = styled.div`
	flex: 0.3;
`;

export const Flex02 = styled.div`
	flex: 0.2;
	padding: 0px 10px;
	word-break : break-word;
`;

export const Flex01 = styled.div`
	flex: 0.1;
	padding: 0px 10px;
`;

export const Flex025 = styled.div`
	display: flex;
	flex: 0.25;
	background-color: #ffffff;
	flex-direction: column;
	padding: 15px;
	margin: 22px 5px;
	border-radius: 23px;
`;

export const StatsContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
`;

export const TabContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 15px 0px;
`;

export const TabLabel = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 10px;
	cursor: pointer;
	color: ${({active}) => (active ? '#323643' : '#989AA1')};
	border-bottom: ${({active}) => (active ? '3px solid #ffd855' : '')};
	border-bottom-radius: 5px;
`;

export const BulkApprovalContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px;
`;

export const CheckBox = styled.div`
	.custom-checkbox
		.custom-control-input:checked
		~ .custom-control-label::before {
		background-color: mediumpurple;
		border: 1px solid mediumpurple;
	}
	.custom-control-label::before {
		height: 20px;
		width: 20px;
		top: 0px;
		border: 1px solid lightgray;
		background: white;
		outline: mediumpurple @media (max-width: 768px) {
			height: 19px;
			width: 19px;
		}
	}

	.custom-control-label::after {
		height: 20px;
		width: 20px;
		top: 0px;
		outline: mediumpurple @media (max-width: 768px) {
			height: 19px;
			width: 19px;
		}
	}
`;

export const StatusColor = styled.div`
	color: ${({color}) => color};
	border: ${({color}) => `1px solid ${color}`};
	border-radius: 23px;
	font-size: 16px;
	font-weight 600;
	display: flex;
	align-items: center;
	padding: 5px;
	justify-content: center;
`;

export const PaginationWrapper = styled.div`
	display: flex;
    justify-content: flex-end;
	float: right
`;

export const ProfileWrapper = styled.div`
	width: 40px;
	height: 40px;
	border: 1px solid #989aa1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SmallProfile = styled.img`
	width: 100%;
	height: 40px;
	border-radius: 50%;
	border: none;
`;

export const Search = styled.div`
	.container-2 {
		width: 300px;
		vertical-align: middle;
		white-space: nowrap;
		position: relative;
	}

	.container-2 input#search {
		width: 40px;
		height: 40px;
		background: #ccc;
		border: none;
		font-size: 10pt;
		float: left;
		color: #262626;
		padding-left: 35px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		color: black;
		background-image: url(../../assets/images/menu-search.png);

		-webkit-transition: width 0.55s ease;
		-moz-transition: width 0.55s ease;
		-ms-transition: width 0.55s ease;
		-o-transition: width 0.55s ease;
		transition: width 0.55s ease;
	}

	.container-2 input#search::-webkit-input-placeholder {
		color: #65737e;
	}

	.container-2 input#search:-moz-placeholder {
		/* Firefox 18- */
		color: #65737e;
	}

	.container-2 input#search::-moz-placeholder {
		/* Firefox 19+ */
		color: #65737e;
	}

	.container-2 input#search:-ms-input-placeholder {
		color: #65737e;
	}

	.container-2 .icon {
		position: absolute;

		margin-left: 17px;
		margin-top: 17px;
		z-index: 1;
		color: white;
	}

	.container-2 input#search:focus,
	.container-2 input#search:active {
		outline: none;
		width: 300px;
	}

	.container-2:hover input#search {
		width: 300px;
	}

	.container-2:hover .icon {
		color: #93a2ad;
	}
`;
export const Spacing = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;
export const FlexNeww = styled.div`
	display: flex;
	justify-content: center !important;
	margin-bottom:20px;
`;

export const HeaderNew = styled.div`
	display: inline-block;
	width:100%;
	padding:20px;`;
export const Results = styled.div`
	float:left`;

export const SearchInput = styled.input`
	padding-left: 30px;
	width: 335px;
	height: 40px;
	cursor: pointer;
	border-radius: 23px;
	background: #f7f8fb;
	border: 1px;
	outline: antiquewhite;
`;