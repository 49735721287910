import styled from 'styled-components';

export const CommentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0px 10px 0px;
`;

export const RowContainer = styled.div`
	display: flex;
	flexdirection: row;
	alignitems: center;
`;

export const Comment = styled.div`
	font-size: 14px;
	text-transform: capitalize;
	margin: 5px 0px 5px 0px;
	font-weight: 400;
`;

export const Profile = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 50%;
`;

export const CreatedAtContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

export const Name = styled.div`
	font-size: 12px;
	text-transform: capitalize;
	margin-bottom: 5px;
	font-weight: 400;
`;

export const CustomDate = styled.div`
	color: #989aa1;
	font-size: 12px;
	line-height: 16px;
`;
