import React from 'react';
import styled from 'styled-components';
import User from '../../services/users';
import {H1, TitleContainer} from '../../helpers/global';
import {ComingSoon, Container, GridContainer, Text} from './Dashboard.styled';

const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

const Home = () => {
	const [token, setToken] = React.useState('');
	const onChangeSetToken = (event) => {
		setToken(event.target.value);
	};

	const _setToken = () => {
		if (token !== '') {
			User.bulkApprove({users: [token], profileStatus: 30}).then(
				(res) => {
					console.log('RESPONSE', res);
				},
				() => {},
			);
			// window.localStorage.setItem('user_authorization_token', token);
			// alert('TOKEN SET');
		}
	};

	return (
		<div>
			{/* <Conatiner>
				<div style={{flex: 1, display: 'flex', margin: 10}}>
					<input
						placeholder={'Enter Token'}
						style={{flex: 1, marginRight: 10, padding: 10}}
						onChange={onChangeSetToken}
					/>
					<button onClick={() => _setToken()} style={{padding: 20}}>
						Set
					</button>
				</div>
			</Conatiner> */}
			<div>
				<TitleContainer style={{margin: 30}}>
					<H1 style={{marginLeft: 10}}>Dashboard</H1>
				</TitleContainer>
				<GridContainer>
					<div style={{margin: '10%'}}>
						<ComingSoon>Coming soon!</ComingSoon>
						<Text style={{marginTop: 20}}>
							It is just one of the many awesome features we will
							be
						</Text>
						<Text>
							releasing soon for your team. We will keep you
							posted!
						</Text>
					</div>
				</GridContainer>
			</div>
		</div>
	);
};

export default Home;
