import styled from 'styled-components';

export const ModalTitleContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	margin: 15px 15px 15px 15px;
	justify-content: space-between;
`;

export const View = styled.div`
	display: flex;
	flex-direction: column;
	width: 300px;
`;

export const PostTypeText = styled.span`
	font-size: 18px;
	font-weight: 600;
`;

export const PostItem = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 15px;
	padding-bottom: 15px;
	cursor: pointer;
	background-color: ${({selected}) => (selected ? '#eae9fa' : '')};
	&:hover {
		background-color: #eae9fa;
	}
`;

export const Border = styled.div`
	height: 1px;
	background-color: #c4c4c4;
`;

export const BtnContainer = styled.div`
	justify-content: center;
	display: flex;
	margin: 15px 0px;
`;