import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
	background-color: #f7f8fb !important;
`;

export const UsersDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 23px;
`;

export const Profile = styled.img`
	height: 100px;
	width: 100px;
	border-radius: 50%;
	object-fit: cover;
`;

export const ProfileContainer = styled.div`
	height: 80px;
	width: 80px;
	border-radius: 50%;
	margin: 25px;
	background-color: #eae9fa;
`;

export const Box = styled.input`
	background-color: #f7f8fb;
	padding: 15px;
	border-radius: 5px;
	border: 1px;
	outline: antiquewhite;
	user-select: none;
`;

export const Flex1 = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	padding: 0px 20px;
`;

export const Flex05 = styled.div`
	display: flex;
	flex-direction: row;
	flex: 0.5;
`;

export const Header = styled.div`
	display: grid;
	width: 100%;
	border-bottom: 2px solid lightgray;
`;
export const UserProfile = styled.div`
	float: left;
	display: flex;
`;
export const Message = styled.div`
	float: right;
`;
export const NameFont = styled.div`
	font-size: 30px;
	margin-top: 25px;
	font-weight: 700;
`;
export const UniFont = styled.div`
	font-size: 14px;
	color: #989aa1;
	font-weight: 400;
`;

export const DisplayMargin = styled.div`
	margin-right: 2%;
`;

export const FlexNew = styled.div`
	display: flex;
	margin-top: 20px;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
`;

export const StatsContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Flex025 = styled.div`
	display: flex;
	flex: 0.25;
	background-color: #ffffff;
	flex-direction: column;
	padding: 15px;
	margin: 22px 5px;
	border-radius: 23px;
`;

export const UserBack = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding-top: 25px;
	padding-left: 25px;
`;

export const ProfileEmpty = styled.img`
	height: 80px;
	width: 80px;
	border-radius: 50%;
	margin: 25px;
`;

export const NameFont01 = styled.div`
	font-size: 30px;
	margin-top: 30px;
	margin-left: 20px;
	font-weight: 700;
`;
