import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {H1, Border} from '../../helpers/global';
import {
	CommentsContainer,
	RowContainer,
	Comment,
	Profile,
	CreatedAtContainer,
	Name,
	CustomDate,
} from './index.styled';

const Comments = ({allComments}) => {
	if (allComments?.length) {
		return (
			<CommentsContainer>
				<H1 style={{marginBottom: 10}}>Comments: </H1>
				{allComments.map((comment, index) => {
					return (
						<>
							<RowContainer key={comment?.key}>
								<Profile src={comment.user?.profilePic?.body} />
								<CreatedAtContainer>
									<Name>
										{comment.user?.firstName +
											' ' +
											comment.user?.lastName}
									</Name>
									<CustomDate>
										{moment(comment?.createdAt)
											.format('DD-MMM-YYYY')
											.replace('-', ' ')
											.replace('-', ' ')}
									</CustomDate>
									<Comment>{comment?.comment}</Comment>
								</CreatedAtContainer>
							</RowContainer>
							{index !== allComments.length - 1 && (
								<Border
									style={{marginTop: 10, marginBottom: 10}}
								/>
							)}
						</>
					);
				})}
			</CommentsContainer>
		);
	}
	return <></>;
};

Comments.propTypes = {
	allComments: PropTypes.array,
};

export default Comments;
