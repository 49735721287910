import styled from 'styled-components';

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto;
	column-gap: 15px;
	row-gap: 30px;
	margin-top: 20px;
	background: white;
	border-radius: 20px;
	margin: 25px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

export const Image = styled.img`
	height: 100%;
	overflow: hidden;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
`;

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 189px;
	background-color: #f7f8fb;
	border-radius: 10px;
	margin-top: 10px;
`;

export const PlaceHolderImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 189px;
	border-radius: 20px;
	margin-top: 10px;
	background-color: #f7f8fb;
`;

export const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
`;

export const Profile = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 50%;
`;

export const CreatedAtContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

export const Name = styled.div`
	font-size: 12px;
	text-transform: capitalize;
	margin-bottom: 5px;
	font-weight: 400;
`;

export const Date = styled.div`
	color: #989aa1;
	font-size: 12px;
	line-height: 16px;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: ${({isLoading}) => (isLoading ? 1 : 0)};
	padding: 50px;
`;

export const SearchInput = styled.input`
	padding-left: 35px;
	width: 335px;
	height: 40px;
	cursor: pointer;
	border-radius: 23px;
	border: 1px;
	outline: antiquewhite;
	position: absolute;
`;

export const CreatePostButton = styled.div`
	padding: 12px;
	background-color: #ffdd55;
	border-radius: 26px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-weight: bold;
	font-size: 14px;
`;
export const CreatePostContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
`;

export const SearchIconImage = styled.img`
	position: relative;
	padding-top: 11px;
	padding-left: 10px;
`;
export const CloseIconImage = styled.img`
	position: relative;
	left: 280px;
	padding-top: 11px;
`;

export const EventProfile = styled.img`
	width: 80%;
	object-fit: cover;
	border-radius: 30px;
`;

export const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	line-height: 32px;
	padding: 10px 0px 10px 0px;
`;

export const Description = styled.div`
	font-size: 15px;
	font-weight: 400;
	line-height: 27px;
	word-break: break-word;
	width: 90%;
`;

export const DescriptionTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 27px;
	padding-top: 20px;
`;

export const EditImg = styled.img`
	width: 40px;
	height: 40px;
	margin-right: 10px;
	object-fit: contain;
`;

export const DeleteImg = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
	margin-right: 10px;
`;

export const Delete = styled.button`
	background-color: #f7f8fb;
	width: 40px;
	height: 40px;
	border: 0;
	outline: 0;
	border-radius: 50%;
	margin: 0px 5px;
	cursor: pointer;
`;
export const Loader = styled.div`
	> div {
		width: 30px;
		height: 30px;
	}
`;

export const FlexNew = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const Tag = styled.div`
	border: ${({color}) => `1px solid ${color}`};
	color: ${({color}) => color};
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 10px;
	padding: 8px 22px;
	border-radius: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	margin-left: 5px;
`;

export const EventProfileRight = styled.img`
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
	margin-left: 20px;
	border: 1px solid #ccc;
	margin-bottom: 15px;
	height: 30%;
`;
export const EventProfileStatic = styled.img`
	width: 100%;
	object-fit: cover;
	border-radius: 30px;
`;
export const LinkImage = styled.img`
	width: 38px;
	height: 38px;
	margin-right: 10px;
`;
