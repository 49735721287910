import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';
import {CustomButton} from '../../helpers/global';

export const ButtonDropzone = ({dropZoneCallback}) => {
	const onDrop = useCallback((acceptedFiles) => {
		dropZoneCallback(
			acceptedFiles.map((file) => {
				let fileData;
				const fileObj = Object.assign(file, {
					body: URL.createObjectURL(file),
				});
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (event) => {
					fileData = Object.assign(fileObj, {
						base64: event.target.result,
					});
				};
				return fileObj;
			}),
		);
		// dropZoneCallback(acceptedFiles);
	}, []);

	const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
		accept: ['image/*', 'application/pdf'],
		noClick: true,
		noKeyboard: true,
		onDrop,
		// maxSize: 10,
	});

	console.log('acceptedFiles', acceptedFiles);

	return (
		<div {...getRootProps({className: 'dropzone'})}>
			<input {...getInputProps()} />
			<CustomButton onClick={open}>Upload Documents</CustomButton>
		</div>
	);
};

ButtonDropzone.propTypes = {
	dropZoneCallback: PropTypes.func.isRequired,
};
