import styled from 'styled-components';

export const StyledMenu = styled.div`
	display: flex;
	position: fixed; // Remove if u want to hide
	flex-direction: column;
	background: ${({theme}) => theme.primaryLight};
	transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-100%)')};
	height: 100vh;
	text-align: left;
	padding-left: 2rem;
	padding-right: 2rem;
	// padding-top: 70px;
	// position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.3s ease-in-out;
	z-index: 2;
	@media (max-width: ${({theme}) => theme.mobile}) {
		width: 100%;
	}
	a {
		font-size: 1rem;
		text-transform: capitalize;
		padding: 0.8rem 0;
		font-weight: bold;
		color: ${({theme}) => theme.primaryDark};
		text-decoration: none;
		transition: color 0.3s linear;
		@media (max-width: ${({theme}) => theme.mobile}) {
			font-size: 1.5rem;
			text-align: center;
		}
		&:hover {
			color: ${({theme}) => theme.primaryHover};
		}
	}
`;

export const ProfileContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 60px;
	padding: 0rem 2rem;
`;

export const Text = styled.span`
	font-size: 16px;
	padding: 2px 0px;
	color: ${({color}) => color};
`;

export const NavText = styled.span`
	font-size: 14px;
	margin-left: 10px;
	color: '#546E7A';
	&:hover {
		color: '#ffdd55';
	}
`;

export const NavContainer = styled.div`
	padding: 13px 0px;
`;
