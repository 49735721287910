import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';

const AdminRoute = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return (
					<Layout>
						<Component {...routeProps} />
					</Layout>
				);
			}}
		/>
	);
};

AdminRoute.propTypes = {
	component: PropTypes.func,
};

export default AdminRoute;
