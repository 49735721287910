import React, {useState, useCallback} from 'react';
import {
	GridContainer,
	Container,
	LoaderContainer,
	Profile,
	CreatedAtContainer,
	Name,
	EventProfile,
	Title,
	Description,
	FlexNew,
	Tag,
	EventProfileRight,
	DescriptionTitle,
	EditImg,
	DeleteImg,
	Loader,
	SmallImages,
	EventProfileStatic,
	LinkImage,
	BottomContainer,
	Date,
	AttendeeBoard,
	Box,
	RowContainer,
} from './index.styled';
import Feed from '../../services/feed';
import moment from 'moment';
import {useHistory} from 'react-router';

import {TitleContainer, H1, FlexRow, Border} from '../../helpers/global';
import EditIcon from '../../assets/images/NewEdit2.png';
import DeleteIcon from '../../assets/images/NewDelete2.png';
import StopWatch from '../../assets/images/stopWatch.png';
import Vector from '../../assets/images/Vector.png';
import Group from '../../assets/images/Group.png';
import IconForward from '../../assets/images/BackNew.png';
import Bitmap from '../../assets/images/Bitmap.png';
import NewAvatar from '../../assets/images/new-avatar.png';
import Link from '../../assets/images/link.png';
import ImageViewer from 'react-simple-image-viewer';
import Events from '../../services/Events';
import {getDatabase, ref, onValue} from 'firebase/database';
import {useParams} from 'react-router-dom';
import Comments from '../Comments';

const getTagColor = (postType) => {
	switch (postType) {
		case 'announcement':
			return '#ffad61';
		case 'article':
			return '#9892e4';
		case 'events':
			return '#8cd7b6';
		case 'discussion':
			return '#84b8e8';
		case 'question':
			return '#FF0000';
		case 'job':
			return '#ffc964';
		case 'marketplace':
			return '#800000';
		case 'polls':
			return '#f684bb';
		case 'memories':
			return '#84b8e8';
		default:
			return '#ffad61';
	}
};

const EventPost = (props) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [individualData, setIndividualData] = React.useState({});
	const history = useHistory();
	const params = useParams();
	const [localDate, setLocalDate] = useState('');
	const [currentImage, setCurrentImage] = React.useState(0);
	const [isViewerOpen, setIsViewerOpen] = React.useState(false);
	const [images, setImages] = React.useState([]);
	const [attendes, setAttendes] = React.useState([]);
	const [allComments, setAllComments] = React.useState([]);
	const db = getDatabase();
	const commentsRef = ref(db, 'posts/' + params?.id);

	React.useEffect(() => {
		onValue(commentsRef, (snapshot) => {
			let comments = [];
			snapshot.forEach((x, index) => {
				const modifiedSnap = {
					...x.val(),
					key: snapshot?._snapshot?.childKeys[index],
				};
				comments.push(modifiedSnap);
			});
			setAllComments(comments);
		});

		setIsLoading(true);
		const params = {skip: 0, limit: 200};
		Feed.getFeedbyId(props.location.state)
			.then((res) => {
				const images = [];
				res.data.events?.photo?.map((photo) => {
					images.push(photo.body);
				});
				setImages(images);
				setIsLoading(false);
				setIndividualData(res.data);
				setIsLoading(false);
				let localDates = moment.utc(res.data.events.startDate).toDate();
				//var localTime  = moment.utc(date).toDate();

				localDates = moment(localDates);
				setLocalDate(localDates);
				setIndividualData(res.data);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log('err', err);
			});

		Events.getAttendees(props.location.state, params)
			.then((res) => {
				setAttendes(res.data);
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, []);

	const removeItem = (id, postType) => {
		setIsLoading(true);
		Feed.delete(id).then(
			() => {
				Feed.getAllFeed({postType, skip: 0, limit: 3}).then(
					(res) => {
						setIsLoading(false);
						history.push('/manage-post');
					},
					() => {},
				);
			},
			() => {},
		);
	};

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);
	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const edit = (item) => {
		history.push(`/edit-post/${item.postType}`, {postID: item._id});
	};

	const renderAttendiesList = () => {
		return (
			<div style={{marginTop: 10}}>
				<H1>Attendes List: </H1>
				<AttendeeBoard>
					{attendes.map((attende, index) => {
						return (
							<Box key={attende._id}>
								<div>{index + 1 + '.' + ' '}</div>
								<div>{attende.attendiee.firstName}</div>
							</Box>
						);
					})}
				</AttendeeBoard>
			</div>
		);
	};

	return (
		<div>
			<Container>
				<div style={{display: 'inline-flex', margin: 20}}>
					<TitleContainer>
						<H1>Manage Posts</H1>
					</TitleContainer>
					<div
						style={{display: 'flex'}}
						onClick={() => history.goBack()}>
						<div style={{cursor: 'pointer', paddingRight: 8}}>
							Back
						</div>
						<div>
							<img
								style={{cursor: 'pointer', width: 13}}
								src={IconForward}
								alt="forward"></img>
						</div>
					</div>
				</div>
				{!isLoading ? (
					// <GridContainer>
					<div style={{margin: 25, marginTop: 0}}>
						<FlexNew>
							<BottomContainer>
								<RowContainer>
									{individualData.createdBy?.profilePic ? (
										<Profile
											src={
												individualData.createdBy
													?.profilePic?.body
											}
										/>
									) : (
										<div>
											<Profile
												style={{
													background: '#B2B3BD',
													width: 46,
													height: 46,
													padding: 9,
												}}
												src={NewAvatar}
												alt="imag"
											/>
										</div>
									)}

									<CreatedAtContainer>
										<Name>
											{'by' +
												' ' +
												individualData.createdBy
													?.firstName +
												' ' +
												individualData.createdBy
													?.lastName}
										</Name>
										<Date>
											{moment(individualData?.createdAt)
												.format('DD-MMM-YYYY')
												.replace('-', ' ')
												.replace('-', ' ')}
										</Date>
									</CreatedAtContainer>
								</RowContainer>
							</BottomContainer>
							<FlexRow style={{marginTop: 10}}>
								<EditImg
									onClick={() => {
										edit(individualData);
									}}
									style={{cursor: 'pointer'}}
									src={EditIcon}
									alt="edit"
								/>
								{!isLoading ? (
									<DeleteImg
										onClick={() =>
											removeItem(
												individualData._id,
												individualData.postType,
											)
										}
										style={{cursor: 'pointer'}}
										src={DeleteIcon}
										alt="delete"></DeleteImg>
								) : (
									<Loader>
										<div className="loader" />
									</Loader>
								)}
								<Tag
									color={getTagColor(
										individualData.postType,
									)}>
									{individualData.postType}
								</Tag>
							</FlexRow>
						</FlexNew>
						<Title>
							{'Title:' + ' ' + individualData?.events?.title}
						</Title>
						<DescriptionTitle>Description:</DescriptionTitle>
						<Description>
							{individualData?.events?.description}
						</Description>
						<div style={{display: 'flex', marginBottom: 10}}>
							<SmallImages
								src={Vector}
								alt="location"></SmallImages>
							<div>
								Location: {individualData?.events?.location}
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: 10}}>
							<SmallImages
								src={StopWatch}
								alt="watch"></SmallImages>
							<div>
								Date:{' '}
								{moment(localDate)
									.format('DD-MMM-YYYY')
									.replace('-', ' ')
									.replace('-', ' ')}
								, Time: {moment(localDate).format('hh:mm A')}
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: 10}}>
							<SmallImages src={Group} alt="grp"></SmallImages>
							<div>
								Cost:{' '}
								{individualData?.events?.isFree === true
									? 'Free'
									: individualData?.events?.price}
							</div>
						</div>
						{individualData.events?.website ? (
							<div
								style={{
									display: 'flex',
									marginBottom: 10,
									marginTop: 20,
								}}>
								<LinkImage src={Link} alt="grp"></LinkImage>
								<div style={{marginTop: 10, fontSize: 14}}>
									<a
										style={{color: '#989AA1'}}
										href={individualData.events.website}
										rel="noreferrer"
										target="_blank">
										{individualData.events.website}
									</a>
								</div>
							</div>
						) : (
							''
						)}

						{individualData.events?.photo &&
						individualData.events?.photo.length > 1 ? (
							<div
								style={{
									paddingTop: 20,
									display: 'flex',
									height: '50%',
								}}>
								<EventProfile
									onClick={() => openImageViewer(0)}
									src={individualData?.events?.photo[0]?.body}
									alt="image"></EventProfile>
								<div style={{width: '17%'}}>
									{individualData.events &&
									individualData.events.photo
										? individualData.events.photo.map(
												(pic, index) => {
													if (index > 0) {
														return (
															<EventProfileRight
																onClick={() =>
																	openImageViewer(
																		index,
																	)
																}
																key={index}
																src={pic.body}
																alt="image"></EventProfileRight>
														);
													}
												},
										  )
										: ''}
								</div>
							</div>
						) : individualData.events?.photo &&
						  individualData.events?.photo.length === 1 ? (
							<div
								style={{
									paddingTop: 20,
									display: 'flex',
									height: '50%',
								}}>
								<EventProfileStatic
									src={individualData?.events?.photo[0]?.body}
									alt="image"></EventProfileStatic>
							</div>
						) : (
							<EventProfileStatic
								style={{padding: 20}}
								src={Bitmap}
								alt="static"></EventProfileStatic>
						)}
						{renderAttendiesList()}
						<Comments allComments={allComments} />
					</div>
				) : (
					// </GridContainer>
					<LoaderContainer isLoading={isLoading}>
						<div className="loader" />
					</LoaderContainer>
				)}
			</Container>
			{isViewerOpen && (
				<div style={{width: '20%', height: '20%'}}>
					<ImageViewer
						src={images}
						currentIndex={currentImage}
						disableScroll={false}
						closeOnClickOutside={true}
						onClose={closeImageViewer}
					/>
				</div>
			)}
		</div>
	);
};

EventPost.propTypes = {
	location: Object,
};

export default EventPost;
