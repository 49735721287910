import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {customStyles} from '../CreatePostModal';
import styled from 'styled-components';

export const ModalWraper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 20px;
	background-color: '#FFFFFF';
	color: red;
`;

let newFiles = [];

const MyDropzone = (props) => {
	const [isOpen, setIsOpen] = React.useState(false);

	// const onDrop = (acceptedFiles) =>{
	// 	console.log('data',acceptedFiles,props)
	// }
	const onDrop = useCallback((acceptedFiles) => {
		// acceptedFiles.map(file=>{
		// 	if(newFiles.length < 4){
		// 		newFiles.push(file);
		// 	}else {
		// 		return
		// 	}

		// })
		// if(newFiles.length > 4){
		// 	return;
		// }else {
		props.setFiles(
			[...props.files],
			acceptedFiles.map((file) => {
				let fileData;
				const fileObj = Object.assign(file, {
					body: URL.createObjectURL(file),
				});
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (event) => {
					fileData = Object.assign(fileObj, {
						base64: event.target.result,
					});
				};
				return fileObj;
			}),
		);
		// const array = acceptedFiles.map((file) => {
		// 	let fileData;
		// 	const fileObj = Object.assign(file, {
		// 		body: URL.createObjectURL(file),
		// 	});
		// 	const reader = new FileReader();
		// 	reader.readAsDataURL(file);
		// 	reader.onload = (event) => {
		// 		fileData = Object.assign(fileObj, {
		// 			base64: event.target.result,
		// 		});
		// 	};
		// 	return fileObj;
		// });

		// props.setFiles([...array]);
	}, []);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		accept: 'image/*',
		maxFiles: 4,
		onDrop: (acceptedFiles) => onDrop(acceptedFiles),
		onDropRejected: () => {
			setIsOpen(true);
		},
	});

	return (
		<div
			{...getRootProps()}
			style={{
				backgroundColor: '#f7f8fb',
				borderRadius: 5,
				padding: 20,
				cursor: 'pointer',
				borderStyle: 'dashed',
				borderWidth: 1,
			}}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : (
				<>
					<p>
						Drag and drop some files here, or click to select files
					</p>
					<em>
						(4 files are the maximum number of files you can drop
						here)
					</em>
				</>
			)}
			<Modal
				isOpen={isOpen}
				onRequestClose={(event) => {
					setIsOpen(false);
					event.stopPropagation();
				}}
				style={customStyles}
				ariaHideApp={false}
				contentLabel="Rejection Modal">
				<ModalWraper>Seems like you added 4+ photos!!</ModalWraper>
			</Modal>
		</div>
	);
};

MyDropzone.propTypes = {
	setFiles: PropTypes.func.isRequired,
	files: PropTypes.array.isRequired,
};

export default MyDropzone;
