import React, {useEffect, useState} from 'react';
import {
	Container,
	ProfileContainer,
	UsersDetailsContainer,
	Box,
	Flex1,
	Flex05,
	Header,
	UserProfile,
	NameFont,
	UniFont,
	LoaderContainer,
	Flex025,
	StatsContainer,
	UserBack,
	NameFont01,
	ProfileEmpty,
} from './index.styled';
import {TitleContainer, H1, H2, CustomButton} from '../../helpers/global';
import User from '../../services/users';
import {useHistory, useParams} from 'react-router';
import moment from 'moment';
import Status from '../Users/components/Status';

import {toast, ToastContainer} from 'react-toastify';
import {toastSettings} from '../../helpers/settings';

import arrow from '../../assets/images/arrow.png';
import arrow1 from '../../assets/images/arrow1.png';
import IconForward from '../../assets/images/icoutline-arrow_forward.png';
import NewAvatar from '../../assets/images/new-avatar.png';
const UserDetails = () => {
	const history = useHistory();
	const {id} = useParams();
	const [userDetails, setUserDetails] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [activeResidentsCount, setActiveResidentsCount] = React.useState(0);
	const [pendingRequestCount, setPendingRequestCount] = React.useState(0);
	const [pendingRenewalCount, setPendingRenewalCount] = React.useState(0);
	const [totalCount, setTotalCount] = React.useState(0);

	//edit
	// console.log('roomNumber', userDetails?.roomNumber);
	const [roomNo, setRoomNo] = useState('');
	const [depDate, setDepdate] = useState('');
	const [roomType, setRoomType] = useState('');
	const [isEditable, setIsEditable] = useState(false);

	useEffect(async () => {
		setIsLoading(true);
		await User.getUserById(id).then(
			(res) => {
				setUserDetails(res.data);
				setRoomNo(res.data?.roomNumber);
				setDepdate(
					moment(res.data?.departureDate).format('DD MMM YYYY'),
				);
				setRoomType(res.data?.roomType);
				setIsLoading(false);
			},
			(err) => console.warn(err),
		);
		const params = {
			skip: 0,
			limit: 10,
		};
		await User.getUsers(params).then(
			(res) => {
				setIsLoading(false);
				setTotalCount(res.pagination.totalCount);
			},
			(err) => {
				setIsLoading(false);
				console.warn(err);
			},
		);

		await User.getUserCount().then(
			(res) => {
				setIsLoading(false);
				res.data.map((usercount) => {
					if (usercount.profileStatus === 30) {
						setActiveResidentsCount(usercount.count);
					} else if (usercount.profileStatus === 20) {
						setPendingRequestCount(usercount.count);
					} else if (usercount.profileStatus === 50) {
						setPendingRenewalCount(usercount.count);
					} else {
						return;
					}
				});
			},
			(err) => {
				setIsLoading(false);
				console.warn(err);
			},
		);
	}, []);

	const deleteUSer = () => {
		User.deleteUser(id).then(
			() => {
				toast('Deleted successfully!', toastSettings);
				history.goBack();
			},
			() => {
				toast(
					'Something went wrong! Please try again after sometime.',
					toastSettings,
				);
			},
		);
	};

	const saveDetails = () => {
		setIsEditable(false);
		User.editUser(id, {
			departureDate: depDate,
			roomNumber: roomNo,
			roomType,
		}).then(
			() => {
				toast('Edited successfully!', toastSettings);
			},
			() => {
				toast(
					'Something went wrong! Please try again after sometime.',
					toastSettings,
				);
			},
		);
	};

	return (
		<Container style={{background: '#F7F8FB'}}>
			<TitleContainer>
				<H1 style={{marginLeft: 10}}>Users</H1>
			</TitleContainer>
			<StatsContainer>
				<Flex025>
					<H2>Total Residents</H2>
					<H1 style={{fontWeight: 700, fontSize: 30}}>
						{totalCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Active Residents</H2>
					<H1
						style={{
							color: '#A9E1C8',
							fontWeight: 700,
							fontSize: 30,
						}}>
						{activeResidentsCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Pending Approval</H2>
					<H1
						style={{
							color: '#B9B9BE',
							fontWeight: 700,
							fontSize: 30,
						}}>
						{pendingRequestCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Pending Renewal</H2>
					<H1 style={{color: '#FFC188'}}>{pendingRenewalCount}</H1>
				</Flex025>
			</StatsContainer>

			{userDetails !== null ? (
				<UsersDetailsContainer>
					<Header>
						<UserBack>
							<TitleContainer>
								<H1 style={{paddingLeft: 25}}>User Details</H1>
							</TitleContainer>
							<CustomButton onClick={() => history.goBack()}>
								Back
							</CustomButton>
							<div>
								<img
									style={{
										cursor: 'pointer',
										paddingRight: 25,
									}}
									src={IconForward}
									alt="forward"></img>
							</div>
						</UserBack>
						<div>
							<UserProfile>
								{userDetails?.profilePic?.body ? (
									<img
										style={{
											width: 80,
											height: 80,
											margin: 25,
											borderRadius: '50%',
											objectFit: 'cover',
										}}
										src={userDetails?.profilePic?.body}
									/>
								) : (
									<div>
										<ProfileEmpty
											style={{
												background: '#B2B3BD',
												padding: 20,
											}}
											src={NewAvatar}
											alt="imag"
										/>
									</div>
								)}
								<div>
									<div
										style={{
											display: 'flex',
											paddingTop: 6,
										}}>
										<NameFont>
											<span>
												{userDetails?.firstName +
													' ' +
													userDetails?.lastName}
											</span>
										</NameFont>
										<NameFont01>
											<span style={{marginTop: 5}}>
												<Status
													ps={
														userDetails.profileStatus
													}
												/>
											</span>
										</NameFont01>
									</div>
									<div>
										<UniFont>
											<span>Resident</span>
										</UniFont>
									</div>
								</div>
							</UserProfile>
						</div>
					</Header>
					<Flex1>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Name</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={
									userDetails?.firstName +
									' ' +
									userDetails?.lastName
								}
								disabled={true}
							/>
						</Flex05>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Email</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={userDetails?.email}
								disabled={true}
							/>
						</Flex05>
					</Flex1>
					<Flex1>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>University</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={userDetails?.university}
								disabled={true}
							/>
						</Flex05>

						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Mobile</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={
									userDetails?.phoneCode +
									' ' +
									userDetails?.phone
								}
								disabled={true}
							/>
						</Flex05>
					</Flex1>
					<Flex1>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Room Type</H2>
							{!isEditable ? (
								<Box
									style={{margin: 10, flex: 0.5}}
									value={roomType}
									disabled={true}
								/>
							) : (
								<select
									id="cars"
									onChange={(ev) =>
										setRoomType(ev.target.value)
									}
									style={{padding: 10, flex: 0.5}}>
									<option value="Campus 4">Campus 4</option>
									<option value="Cluster 6">Cluster 6</option>
									<option value="Studio">Studio</option>
									<option value="Studio Large">
										Studio Large
									</option>
									<option value="Studio Deluxe">
										Studio Deluxe
									</option>
									<option value="Twodio">Twodio</option>
								</select>
							)}
						</Flex05>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Room No.</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={roomNo}
								disabled={!isEditable}
								onChange={(e) => setRoomNo(e.target.value)}
							/>
						</Flex05>
					</Flex1>
					<Flex1>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Departure Date</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={
									isEditable
										? moment(depDate).format('YYYY-MM-DD')
										: moment(depDate).format('DD MMM YYYY')
								}
								type={isEditable ? 'date' : 'text'}
								disabled={!isEditable}
								onChange={(e) => setDepdate(e.target.value)}
								onkeydown="return false"
							/>
						</Flex05>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Date Of Birth</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={moment(userDetails?.dob)
									.format('DD-MMM-YYYY')
									.replace('-', ' ')
									.replace('-', ' ')}
								disabled={true}
							/>
						</Flex05>
					</Flex1>
					<Flex1>
						<Flex05 style={{alignItems: 'center'}}>
							<H2 style={{flex: 0.3}}>Field Of Study</H2>
							<Box
								style={{margin: 10, flex: 0.5}}
								value={userDetails?.major}
								disabled={true}
							/>
							{/* <H2>
									{moment(userDetails?.departureDate).format(
										'LL',
									)}
								</H2> */}
						</Flex05>
					</Flex1>
					<Flex1>
						<CustomButton
							style={styles.btn}
							onClick={() =>
								!isEditable
									? setIsEditable(true)
									: saveDetails()
							}>
							{!isEditable ? 'Edit Details' : 'Save Details'}
						</CustomButton>
						<CustomButton
							style={styles.btn}
							onClick={() => deleteUSer()}>
							Delete User
						</CustomButton>
					</Flex1>
				</UsersDetailsContainer>
			) : (
				isLoading && (
					<LoaderContainer>
						<div className="loader" />
					</LoaderContainer>
				)
			)}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</Container>
	);
};

const styles = {
	btn: {
		backgroundColor: '#ffd855',
		display: 'flex',
		alignSelf: 'center',
		margin: 20,
		fontSize: 14,
		color: '#FFFFFF',
		fontWeight: 700,
	},
};

export default UserDetails;
