import * as axios from '../network';
import config from '../config/apiconfig';

class User {
	static getUsers(params) {
		var queryString = Object.keys(params)
			.map((key) => key + '=' + params[key])
			.join('&');

		return axios.appRequest({
			method: axios.GET,
			url: config.allusers + `?${queryString}`,
		});
	}

	static getUserById(id) {
		return axios.appRequest({
			method: axios.GET,
			url: config.user + id,
		});
	}

	static getUserCount() {
		return axios.appRequest({
			method: axios.GET,
			url: config.userCount,
		});
	}

	static bulkApprove(data) {
		return axios.appRequest({
			method: axios.PUT,
			url: config.user + 'bulkApprove',
			data,
		});
	}

	static bulkReject(data) {
		return axios.appRequest({
			method: axios.PUT,
			url: config.user + 'bulkReject',
			data,
		});
	}

	static getUserByUniversity(params) {
		var queryString = Object.keys(params)
			.map((key) => key + '=' + params[key])
			.join('&');
		return axios.appRequest({
			method: axios.GET,
			url: config.allusers + `?${queryString}`,
		});
	}

	static deleteUser(id) {
		return axios.appRequest({
			method: axios.DELETE,
			url: config.user + id,
		});
	}

	static editUser(id, data) {
		return axios.appRequest({
			method: axios.PUT,
			url: config.user + id,
			data: data,
		});
	}
}

export default User;
