// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'ap-southeast-2',
	aws_cognito_identity_pool_id:
		'ap-southeast-2:899f809d-b5b1-48e5-a5eb-c1131e072db2',
	aws_cognito_region: 'ap-southeast-2',
	aws_user_pools_id: 'ap-southeast-2_BB0Ar4bIX',
	aws_user_pools_web_client_id: '212gkghqsavfb6ihlfquu23pi',
	oauth: {
		domain: 'amigosqa.auth.ap-southeast-2.amazoncognito.com',
		scope: [
			'phone',
			'email',
			'openid',
			'profile',
			'aws.cognito.signin.user.admin',
		],
		redirectSignIn: 'amigos://',
		redirectSignOut: 'amigos://',
		responseType: 'code',
	},
	federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;
