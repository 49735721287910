import React, {useEffect, useState} from 'react';
import Switch from 'react-switch';
import moment from 'moment';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {useParams, useHistory} from 'react-router-dom';
import {ButtonDropzone} from '../../components/ButtonDropZone';
import {Border, H1, TitleContainer} from '../../helpers/global';
import {
	GridContainer,
	Text,
	BottomContainer,
	GridItems,
	Back,
	TextEllipsis,
	PDFIcon,
	DocIcon,
	ImageIcon,
	DocumentNameWrapper,
	NoDocumentsContainer,
	WrapIcons,
} from './Storage.styled';

import {formatBytes} from '../../helpers/utils';
import IconForward from '../../assets/images/NewBack1.png';
import Pdf from '../../assets/images/pdf.png';
import Img from '../../assets/images/img.png';
import DeleteIcon from '../../assets/images/NewDelete2.png';
import {Flex} from '../EditPost/index.styled';
import Document from '../../services/document';
import {DeleteImg, Loader} from '../../components/Event/index.styled';

const Icon = ({mimeType}) => {
	switch (mimeType) {
		case 'application/pdf':
			return <PDFIcon src={Pdf} />;

		default:
			return <ImageIcon src={Img} />;
	}
};

Icon.propTypes = {
	mimeType: PropTypes.string,
};

const DocumentStorage = () => {
	const [files, setFiles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();
	const history = useHistory();

	const todaysDate = moment().format('ll');

	const dropZoneCallback = (newFiles) => {
		if (newFiles.length > 0) {
			upload(newFiles);
		}
	};

	const getDocuments = () => {
		setIsLoading(true);
		Document.getDocuments(params?.id).then(
			(res) => {
				setFiles(res.data);
				setIsLoading(false);
			},
			(err) => {
				setFiles([]);
				setIsLoading(false);
			},
		);
	};

	const upload = (files) => {
		var formData = new FormData();
		formData.append('documentSection', params?.id);

		files.map((file, i) => {
			formData.append('file', file, file.name);
		});

		Document.uploadDocument(formData).then(
			(res) => {
				getDocuments();
			},
			(err) => {
				console.log('RES--->', err);
			},
		);
	};

	const toggleSwitch = (id, value) => {
		Document.updateisViewable(id, value).then(
			(res) => {
				const modilfiedFiles = files.map((item) => {
					if (item._id === id) {
						item.isViewable = value;
					}
					return item;
				});
				setFiles(modilfiedFiles);
			},
			(err) => {
				toast('Something went wrong!!', {
					position: 'bottom-center',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			},
		);
	};

	const deleteItem = (id) => {
		Document.deleteDocument(id).then(
			(res) => {
				if (res) {
					const newArr = files.filter((item) => item._id !== id);
					setFiles(newArr);
				}
			},
			(err) => {
				toast('Something went wrong!!', {
					position: 'bottom-center',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			},
		);
	};

	useEffect(() => {
		getDocuments();
	}, []);

	return (
		<>
			<TitleContainer style={{margin: 30}}>
				<H1 style={{marginLeft: 10}}>Document Storage</H1>
			</TitleContainer>
			<GridContainer>
				<GridItems showBorder>
					<Text className="bold">Document Name</Text>
					<Text className="bold">Uploaded By</Text>
					<Text className="bold">Uploaded Date</Text>
					<Text className="bold">File Size</Text>
					<Text className="bold">Display to Users</Text>
				</GridItems>

				<>
					{isLoading ? (
						<Loader
							style={{
								alignItems: 'center',
								display: 'flex',
								padding: 50,
								justifyContent: 'center',
							}}>
							<div className="loader"></div>
						</Loader>
					) : (
						<>
							{files.length > 0 ? (
								files.map((item) => (
									<GridItems
										key={item._id}
										showBorder
										showHoverEffect>
										<DocumentNameWrapper>
											<Icon mimeType={item.fileType} />
											<TextEllipsis>
												{item.name}
											</TextEllipsis>
										</DocumentNameWrapper>
										<Text>{item.createdBy?.email}</Text>
										<Text>{todaysDate}</Text>
										<Text>{formatBytes(item.size)}</Text>
										<WrapIcons>
											<Switch
												onChange={() =>
													toggleSwitch(
														item._id,
														!item.isViewable,
													)
												}
												checked={item.isViewable}
												offColor={'#EAE9FA'}
												onColor={'#9892E4'}
												offHandleColor={'#9892E4'}
												onHandleColor={'#EAE9FA'}
												checkedIcon={<></>}
												uncheckedIcon={<></>}
											/>
											<DeleteImg
												style={{cursor: 'pointer'}}
												onClick={() =>
													deleteItem(item._id)
												}
												src={DeleteIcon}
												alt="delete"
											/>
										</WrapIcons>
									</GridItems>
								))
							) : (
								<NoDocumentsContainer>
									No documents found!
								</NoDocumentsContainer>
							)}
						</>
					)}
				</>

				<BottomContainer>
					<Flex
						style={{alignItems: 'center', cursor: 'pointer'}}
						onClick={() => history.goBack()}>
						<Back src={IconForward} alt="back" />
						<Text>Back</Text>
					</Flex>
					<ButtonDropzone dropZoneCallback={dropZoneCallback} />
				</BottomContainer>
			</GridContainer>
		</>
	);
};

export default DocumentStorage;
