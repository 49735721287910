import * as axios from '../network';
import config from '../config/apiconfig';

class Feed {
	static getAllFeed(params) {
		var queryString = Object.keys(params)
			.map((key) => key + '=' + params[key])
			.join('&');

		return axios.appRequest({
			method: axios.GET,
			url: config.feed.allFeed + `?${queryString.replace(/\s/g, '')}`,
		});
	}

	static uploadEditPosts(data, postID) {
		return axios.appRequest({
			method: postID ? axios.PUT : axios.POST,
			url: postID
				? config.feed.feedById + '/' + postID
				: config.feed.feedById,
			data,
		});
	}

	static getFeedbyId(id) {
		return axios.appRequest({
			method: axios.GET,
			url: config.feed.feedById + '/' + id,
		});
	}

	static delete(id) {
		return axios.appRequest({
			method: axios.DELETE,
			url: config.feed.feedById + '/' + id,
		});
	}
}

export default Feed;
