import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto;
	column-gap: 15px;
	background: white;
	border-radius: 20px;
	margin: 45px;
`;

export const ComingSoon = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 700;
	font-size: 30px;
`;

export const Text = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 400;
	font-size: 18px;
`;
