import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import './App.css';
import Routes from './routes';
import {theme} from './helpers/theme';
import {Amplify, Auth} from 'aws-amplify';
import {AmplifyAuthenticator, AmplifySignIn} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import awsconfigdev from './amplify-auth/aws-exports-dev';
import awsconfigdqa from './amplify-auth/aws-exports-qa';
import awsconfigprod from './amplify-auth/aws-exports-prod';
import {initializeApp} from 'firebase/app';

const _firbaseBaseUrl = process.env.REACT_APP_FIREBASE_CONFIG;

if (process.env.REACT_APP_ENV === 'QA') {
	Amplify.configure(awsconfigdqa);
} else if (process.env.REACT_APP_ENV === 'DEV') {
	Amplify.configure(awsconfigdev);
} else {
	Amplify.configure(awsconfigprod);
}

const UnAuth = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: inherit;
	> span {
		text-align: center;
	}
	> button {
		border: none;
		background-color: #ffdd55;
		border-radius: 10px;
		padding: 4px 10px;
		margin-top: 20px;
		cursor: pointer;
	}
`;

const AuthStateApp = () => {
	const [authState, setAuthState] = React.useState();
	const [user, setUser] = React.useState();
	const [autorized, setAuthorized] = React.useState(false);
	const firebaseConfig = {
		// ...
		// The value of `databaseURL` depends on the location of the database
		databaseURL: _firbaseBaseUrl,
	};

	const reload = () => {
		localStorage.clear();
		location.reload();
	};

	React.useEffect(() => {
		initializeApp(firebaseConfig);
	}, []);

	React.useEffect(() => {
		if (authState === undefined) {
			Auth.currentAuthenticatedUser().then((authData) => {
				const userType = authData?.attributes['custom:userType'];
				if (userType === 'ADMIN') {
					setAuthState(AuthState.SignedIn);
					setUser(authData);
					setAuthorized(true);
				}
			});
		}

		return onAuthUIStateChange((nextAuthState, authData) => {
			const userType = authData?.attributes['custom:userType'];
			if (userType === 'ADMIN') {
				localStorage.setItem('user', JSON.stringify(authData));
				setAuthState(nextAuthState);
				setUser(authData);
				setAuthorized(true);
			} else {
				setUser(authData);
				setAuthorized(false);
			}
		});
	}, [authState]);

	if (authState === AuthState.SignedIn && user && autorized) {
		return <Routes userData={user} />;
	} else if (!autorized && user) {
		return (
			<UnAuth>
				<span>
					Sorry you are not authorised to access this application!
					<br></br> Please contact{' '}
					<b>
						<a href="mailto:contactus@amigos.community">
							contactus@amigos.community
						</a>
					</b>
				</span>
				<button onClick={() => reload()}>Logout</button>
			</UnAuth>
		);
	} else {
		return (
			<AmplifyAuthenticator>
				<AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp>
					<div slot="federated-buttons"></div>
				</AmplifySignIn>
			</AmplifyAuthenticator>
		);
	}
};

function App() {
	return (
		<ThemeProvider theme={theme} style={{flex: 1}}>
			<AuthStateApp />
		</ThemeProvider>
	);
}

export default App;
