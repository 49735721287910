import React from 'react';
import {useHistory, useParams} from 'react-router';

import Feed from '../../services/feed';
import PostCard from '../../components/PostCard';
import CreatePostModal from '../../components/CreatePostModal';

import {TitleContainer, H1, Button} from '../../helpers/global';
import {
	GridContainer,
	Container,
	LoaderContainer,
	SearchInput,
	CreatePostButton,
	CreatePostContainer,
	SearchIconImage,
	CloseIconImage,
} from './index.styled';
import {Listitems} from '../ManagePosts';
import ManagePostsHeader from '../../components/ManagePostsHeader';
import CloseIcon from '../../assets/images/closeIcon.png';
import SearchNew from '../../assets/images/searchNew.png';

const AllPosts = () => {
	const {id} = useParams();
	const history = useHistory();
	const [isLoading, setIsLoading] = React.useState(false);
	const [modalIsOpen, setModal] = React.useState(false);
	const [selectedPostType, setSelectedPostType] = React.useState(0);
	const [items, setItems] = React.useState([]);

	const closeModal = () => {
		setModal(false);
	};

	const handleCreateBtn = () => {
		setModal(false);
		history.push(
			`/create-post/${Listitems[selectedPostType].name.toLowerCase()}`,
		);
	};

	React.useEffect(() => {
		setIsLoading(true);
		Feed.getAllFeed({postType: id.toLowerCase(), skip: 0, limit: 50}).then(
			(res) => {
				setItems([...res.data]);
				setIsLoading(false);
			},
			() => {
				setIsLoading(false);
			},
		);
	}, []);
	return (
		<>
			<Container>
				<TitleContainer>
					<H1>Manage Posts</H1>
				</TitleContainer>
				<CreatePostContainer>
					<div>
						<SearchInput
							type="search"
							id="search"
							placeholder="Search"
						/>
						<div>
							<SearchIconImage
								src={SearchNew}
								alt="search"></SearchIconImage>
							<CloseIconImage
								src={CloseIcon}
								alt="search"></CloseIconImage>
						</div>
					</div>
					<div>
						<CreatePostButton onClick={() => setModal(true)}>
							Create New Post
						</CreatePostButton>
					</div>
				</CreatePostContainer>
				{!isLoading ? (
					<GridContainer>
						{items.map((item) => (
							<PostCard
								item={item}
								key={item._id}
								setData={setItems}
								allKey="all"
							/>
						))}
					</GridContainer>
				) : (
					<LoaderContainer isLoading={isLoading}>
						<div className="loader" />
					</LoaderContainer>
				)}

				{modalIsOpen && (
					<CreatePostModal
						closeModal={closeModal}
						setSelectedPostType={setSelectedPostType}
						handleCreateBtn={handleCreateBtn}
						modalIsOpen={modalIsOpen}
						selectedPostType={selectedPostType}
						Listitems={Listitems}
					/>
				)}
			</Container>
		</>
	);
};

export default AllPosts;
