import axios from 'axios';
import {Auth} from 'aws-amplify';
import {Keys} from '../config';
// import Utils from 'helper/Utils';

const _baseUrl = process.env.REACT_APP_BASE_URL;
// var pendingRequests = 0;

export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';
export const PUT = 'PUT';

/**
 * Create an Axios Client with defaults
 */
const appClient = axios.create({
	baseURL: _baseUrl,
});

const getUser = async () => {
	let keyPrefix = '';
	await Auth.currentAuthenticatedUser().then(
		(user) => {
			keyPrefix = user.keyPrefix + '.' + user.username;
		},
		(err) => {
			console.log('ERR', err);
		},
	);
	return keyPrefix;
};

appClient.interceptors.request.use(async (config) => {
	const key = await getUser();
	if (window.localStorage.getItem(`${key}.accessToken`)) {
		config.headers.common.authorization = window.localStorage.getItem(
			`${key}.accessToken`,
		);
	}
	return config;
});

appClient.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (
			error.response.status === 401 &&
			window.location.href.search('login') === -1 &&
			window.location.href.search('reset-password') === -1
		) {
			window.location.href = Keys.appBaseName + 'login';
		}
		return Promise.reject(error);
	},
);

// function hideLoader() {
// 	pendingRequests--;
// 	if (!pendingRequests) {
// 		Utils.hideLoadingIndicator();
// 	}
// }

/**
 * Create an Axios Client for general usage
 */
const generalClient = axios.create();

/**
 * Request Wrapper with base url set to _baseUrl.
 */
const appRequest = function (options) {
	// Utils.showLoadingIndicator();
	// pendingRequests++;
	const onSuccess = function (response) {
		// hideLoader();
		if (response && response.data.meta && response.data.meta.code) {
			return response.data;
		} else {
			return false;
		}
	};

	const onError = function (error) {
		// hideLoader();
		if (error.response) {
			console.log('ERROR', error);
			// Utils.showAlertMessage(error.response.data.meta.message);
			// document.addEventListener('keydown', Utils.escFunction, false);
		} else {
			console.log('Error Message:', error.message);
		}

		return Promise.reject(error.response || error.message);
	};

	return appClient(options).then(onSuccess).catch(onError);
};

/**
 * General api wrpper. No base URL is set
 */
const generalRequest = function (options) {
	const onSuccess = function (response) {
		console.debug('Request Successful!', response);
		return response.data;
	};

	const onError = function (error) {
		console.log(error);
		console.error('Request Failed:', error.config);

		if (error.response) {
			// Request was made but server responded with something
			// other than 2xx

			console.log('Status:', error.response.status);
			console.log('Data:', error.response.data);
			console.log('Headers:', error.response.headers);
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error('Error Message:', error.message);
		}

		return Promise.reject(error.response || error.message);
	};

	return generalClient(options).then(onSuccess).catch(onError);
};

export {appRequest, generalRequest};
