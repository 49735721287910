import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
	Tag,
	Title,
	Desc,
	Image,
	Delete,
	BottomContainer,
	Profile,
	CreatedAtContainer,
	Name,
	PlaceHolderImage,
	Date,
	Loader,
	ImageWrapper,
	EditImg,
	DeleteImg,
	ImageSetting,
	ImageCountBox,
} from './PostCard.styled';
import {Container} from './PostCard.styled.js';
import {useHistory} from 'react-router';
import Feed from '../../services/feed';
import {ReactComponent as Remove} from '../../assets/images/delete.svg';
import {ReactComponent as Edit} from '../../assets/images/edit.svg';
import EditIcon from '../../assets/images/edit.png';
import DeleteIcon from '../../assets/images/delete.png';
import NewEditIcon from '../../assets/images/NewEdit2.png';
import NewDeleteIcon from '../../assets/images/NewDelete2.png';
import {FlexRow} from '../../helpers/global';
import Bitmap from '../../assets/images/Bitmap.png';
import BitmapNew from '../../assets/images/Bitmap(1).png';
import BitmapNew3 from '../../assets/images/Bitmap(3).png';

const getTagColor = (postType) => {
	switch (postType) {
		case 'announcement':
			return '#ffad61';
		case 'article':
			return '#9892e4';
		case 'events':
			return '#8cd7b6';
		case 'discussion':
			return '#84b8e8';
		case 'question':
			return '#FF0000';
		case 'job':
			return '#ffc964';
		case 'marketplace':
			return '#800000';
		case 'polls':
			return '#f684bb';
		case 'memories':
			return '#84b8e8';
		default:
			return '#ffad61';
	}
};

const PostCard = ({item, setData,allKey}) => {
	const details = item[item.postType];
	const history = useHistory();
	const [isLoading, setIsLoading] = React.useState(false);

	const edit = (item) => {
		if(item.postType === "events"){
			history.push(`/edit-post/${item.postType}`, {postID: item._id});
		}
		if (item.postType === 'announcement') {
			history.push(`/edit-post/${item.postType}`, {postID: item._id});
		}
		if (item.postType === 'article') {
			history.push(`/edit-post/${item.postType}`, {postID: item._id});
		}
		if (item.postType === 'job') {
			history.push(`/edit-post/${item.postType}`, {postID: item._id});
		}

	};

	const removeItem = (id, postType) => {
		setIsLoading(true);
		if(allKey !== undefined){
			Feed.delete(id).then(
				() => {
					Feed.getAllFeed({postType, skip: 0, limit: 50}).then(
						(res) => {
							setData([...res.data]);
							setIsLoading(false);
						},
						() => {},
					);
				},
				() => {},
			);
		}else {
			Feed.delete(id).then(
				() => {
					Feed.getAllFeed({postType, skip: 0, limit: 3}).then(
						(res) => {
							setData([...res.data]);
							setIsLoading(false);
						},
						() => {},
					);
				},
				() => {},
			);
		}
		
	};

	const handleIndividualPost = (id) => {
		if(item.postType === "events"){
			history.push(`/manage-post/event/${id}`,id)
		}
		if (item.postType === 'announcement') {
			history.push(`/manage-post/announcement/${id}`, id);
		}
		if (item.postType === 'job') {
			history.push(`/manage-post/job/${id}`, id);
		}
		if (item.postType === 'article') {
			history.push(`/manage-post/article/${id}`, id);
		}
	}
	const truncate = (str, n) =>{
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	  }
	return (
		<Container>
			<Tag color={getTagColor(item.postType.toLowerCase())}>
				{item.postType === 'events' ? 'Event' : item.postType}
			</Tag>
			<Title>{truncate(details.title,25)}</Title>
			<Desc>{truncate(details.description,78)}</Desc>
			{details?.photo[0]?.body ? (
				<ImageSetting>
					
				<ImageWrapper onClick={() => handleIndividualPost(item._id)}>
					<Image src={details?.photo[0]?.body} alt={'No Image'} />
				</ImageWrapper>
				{details.photo.length > 1 ? (
					<ImageCountBox onClick={() => handleIndividualPost(item._id)}>{'+' + (details.photo.length-1)}</ImageCountBox>
				) : "" }
				
				</ImageSetting>
			) : (
				<ImageWrapper onClick={() => handleIndividualPost(item._id)}>
				<Image src={BitmapNew3} alt={'No Image'} />
			</ImageWrapper>
			)}
			<BottomContainer>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<Profile src={item.createdBy?.profilePic?.body} />
					<CreatedAtContainer>
						<Name>
							{'by' +
								' ' +
								item.createdBy?.firstName +
								' ' +
								item.createdBy?.lastName}
						</Name>
						<Date>{moment(item?.createdAt).format('DD-MMM-YYYY').replace('-',' ').replace('-',' ')}</Date>
					</CreatedAtContainer>
				</div>
				<FlexRow >
					{/* <Delete style = {{background:'#F7F8FB'}} onClick={() => {edit(item)}} > */}
						<EditImg onClick={() => {edit(item)}} style = {{marginRight:10}} src={NewEditIcon} alt="edit"></EditImg>
					{/* </Delete> */}
					{!isLoading ? (
						// <Delete style = {{background:'#F7F8FB'}}
							//onClick={() => removeItem(item._id, item.postType)}>
							<DeleteImg onClick={() => removeItem(item._id, item.postType)}
								src={NewDeleteIcon}
								alt="delete"></DeleteImg>
						// </Delete>
					) : (
						<Loader>
							<div className="loader" />
						</Loader>
					)}
				</FlexRow>
			</BottomContainer>
		</Container>
	);
};

PostCard.propTypes = {
	item: PropTypes.object.isRequired,
	setData: PropTypes.func.isRequired,
	allKey: PropTypes.string
};

export default PostCard;
