import styled from 'styled-components';
export const NameAndImage = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Name = styled.div`
	margin-right: 10px;
    color: #fff;
    font-weight:400;
`;

export const ProfileImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: cover;
	margin-right: 10px;
	border: 3px solid #ffff;
	border-radius: 25px;
`;

export const Border = styled.div`
	border: 0.2px solid #fff;
	height: 30px;
	margin-right: 10px;
`;