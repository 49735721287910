import React, {useEffect, useState} from 'react';
import {bool, func, string} from 'prop-types';
import {NavLink} from 'react-router-dom';
import {
	StyledMenu,
	ProfileContainer,
	NavContainer,
	Text,
	NavText,
} from './Menu.styled';
import Dashboard from '../../../assets/images/dashboard.svg';
import User from '../../../assets/images/user.svg';
import ManagePost from '../../../assets/images/managepost.svg';
import Help from '../../../assets/images/help.svg';
import Settings from '../../../assets/images/settings.svg';
import {ReactComponent as Logo} from '../../../assets/images/logo.svg';
//import {ReactComponent as Document} from '../../../assets/images/pdf.svg';
import Document from '../../../assets/images/Document.png';
import DocumentStore from '../../../assets/images/DocumentStore.png';
import DocumentStore1 from '../../../assets/images/DocumentStore1.png';
import {withRouter, useHistory} from 'react-router';

const Menu = ({open, ...props}) => {
	const history = useHistory();
	const isHidden = open ? true : false;
	const [active, setActive] = useState('dashboard');

	return (
		<StyledMenu open={open} aria-hidden={!isHidden} {...props}>
			<ProfileContainer>
				<div
					onClick={() => history.push('/')}
					style={{cursor: 'pointer'}}>
					<Logo />
				</div>
			</ProfileContainer>
			<NavContainer>
				<Text color={'#546E7A'} style={{margin: 20}}>
					Overview
				</Text>
				<div style={{marginTop: 20}}>
					<NavLink
						exact
						to="/"
						style={{
							alignItems: 'center',
							display: 'flex',
							borderRadius: 12,
						}}
						activeStyle={{background: '#FFD855'}}>
						<img
							src={Dashboard}
							alt="dashboard"
							className={`${
								active === 'dashboard'
									? 'image-user-1'
									: 'image-user'
							}`}></img>
						<NavText
							className={`${
								active === 'dashboard'
									? 'text-user-1'
									: 'text-user'
							}`}>
							Dashboard
						</NavText>
					</NavLink>
				</div>
			</NavContainer>
			<NavContainer>
				<Text color={'#546E7A'} style={{margin: 20}}>
					User Management
				</Text>
				<div style={{marginTop: 20}}>
					<NavLink
						style={{
							alignItems: 'center',
							display: 'flex',
							borderRadius: 12,
						}}
						activeStyle={{background: '#FFD855'}}
						exact
						to="/users">
						<img src={User} alt="user" className="image-user"></img>
						<NavText className="text-user">Users</NavText>
					</NavLink>
				</div>
			</NavContainer>
			<NavContainer>
				<Text color={'#546E7A'} style={{margin: 20}}>
					Applications
				</Text>
				<NavLink
					exact
					to="/manage-post"
					style={{
						alignItems: 'center',
						display: 'flex',
						marginTop: 20,
						borderRadius: 12,
					}}
					activeStyle={{background: '#FFD855'}}>
					<img
						src={ManagePost}
						alt="managePost"
						className="image-user"></img>{' '}
					<NavText className="text-user">Manage Posts</NavText>
				</NavLink>
				<NavLink
					exact
					to="/documents"
					style={{
						alignItems: 'center',
						display: 'flex',
						borderRadius: 12,
					}}
					activeStyle={{background: '#FFD855'}}>
					<img
						style={{width: 20}}
						src={DocumentStore1}
						className="image-user"
						alt="doc"></img>
					<NavText className="text-user">Document Storage</NavText>
				</NavLink>
			</NavContainer>
			<NavContainer>
				<Text color={'#546E7A'} style={{margin: 20}}>
					Support
				</Text>
				<NavLink
					exact
					to="/settings"
					style={{
						alignItems: 'center',
						display: 'flex',
						marginTop: 20,
						borderRadius: 12,
					}}
					activeStyle={{background: '#FFD855'}}>
					<img src={Settings} alt="settings" className="image-user" />
					<NavText className="text-user">Settings</NavText>
				</NavLink>
				<NavLink
					exact
					to="/help"
					style={{
						alignItems: 'center',
						display: 'flex',
						borderRadius: 12,
					}}
					activeStyle={{background: '#FFD855'}}
					// onClick={() => props.setOpen(false)}
				>
					<img src={Help} alt="help" className="image-user" />
					<NavText className="text-user">Helpdesk</NavText>
				</NavLink>
			</NavContainer>
		</StyledMenu>
	);
};

Menu.propTypes = {
	open: bool.isRequired,
	setOpen: func,
};

export default withRouter(Menu);
