import styled from 'styled-components';

export const Button = styled.div`
	padding: 15px;
	background-color: #ffdd55;
	border-radius: 26px;
	cursor: pointer;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
`;

export const H1 = styled.span`
	font-size: 26px;
	line-height: 39px;
	color: #323643;
	font-weight: 600;
`;

export const H2 = styled.span`
	font-size: 16px;
	color: #989aa1;
	font-weight: 500;
	line-height: 21px;
`;
export const H3 = styled.span`
	margin-right: 20px;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
	color: #323643;
	line-height: 21px;
	cursor: pointer;
`;
export const H6Opacity = styled.span`
	font-size: 12px;
	line-height: 16px;
	color: #323643;
	opacity: 0.5;
`;

export const H6Error = styled.span`
	font-size: 12px;
	line-height: 16px;
	color: red;
	opacity: 1;
	padding-top: 8px;
`;

export const Border = styled.div`
	border: 1px solid #eae9fa;
`;

export const BrandLine = styled.div`
	border: 2px solid #ffd855;
	border-radius: 5px;
	margin: 30px 0px;
`;

export const CustomButton = styled.div`
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 23px;
	cursor: pointer;
	background-color: #ffd855;
	color: #ffffff;
	font-weight: 700;
`;

export const InputButton = styled.input`
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 23px;
	border: 0;
	cursor: pointer;
`;

export const AesTrick = styled.span`
	color: red;
	opacity: 1 !important;
	font-size: 18px;
`;

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const FlexColum = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 23px;
	margin-top: 20px;
`;
