import React from 'react';
import {H1, TitleContainer} from '../../helpers/global';
import {ComingSoon, Container, GridContainer, Text} from './Help.styled';

const Help = () => {
	return (
		<div>
			<TitleContainer style={{margin: 30}}>
				<H1 style={{marginLeft: 10}}>Help</H1>
			</TitleContainer>
			<GridContainer>
				<div style={{margin: '10%'}}>
					<ComingSoon>Coming soon!</ComingSoon>
					<Text style={{marginTop: 20}}>
						It is just one of the many awesome features we will be
					</Text>
					<Text>
						releasing soon for your team. We will keep you posted!
					</Text>
				</div>
			</GridContainer>
		</div>
	);
};

export default Help;
