import React from 'react';
import Modal from 'react-modal';
import {
	Text,
	GridContainer,
	MasterGridContainer,
	LoaderContainer,
	Conatiner,
	CreatePostContainer,
	SearchInput,
	SearchIconImage,
	CloseIconImage,
	CreatePostButton,
} from './MangePosts.styled';
import {TitleContainer, FlexRow, H1} from '../../helpers/global';
import Feed from '../../services/feed';
import PostCard from '../../components/PostCard';
import {useHistory} from 'react-router';
import CreatePostModal from '../../components/CreatePostModal';
import SearchNew from '../../assets/images/searchNew.png';
import SeeAll from '../../assets/images/see-all.png';
import CloseIcon1 from '../../assets/images/SearchIcon1.png';

export const Listitems = [
	{
		name: 'Announcement',
	},
	{
		name: 'Event',
	},
	{
		name: 'Article',
	},
	{
		name: 'Job',
	},
];

const ManagePosts = (props) => {
	let history = useHistory();
	const [modalIsOpen, setModal] = React.useState(false);
	const [selectedPostType, setSelectedPostType] = React.useState(0);
	const [events, setEvents] = React.useState([]);
	const [anouncements, setAnouncements] = React.useState([]);
	const [articles, setArticles] = React.useState([]);
	const [jobs, setJobs] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const closeModal = () => {
		setModal(false);
	};

	const handleCreateBtn = () => {
		setModal(false);
		history.push(
			`/create-post/${Listitems[selectedPostType].name.toLowerCase()}`,
		);
	};

	const getAlldata = async () => {
		setIsLoading(true);
		await Promise.all([
			Feed.getAllFeed({postType: 'events', skip: 0, limit: 3}).then(
				(res) => {
					setEvents([...res.data]);
				},
				() => {},
			),
			Feed.getAllFeed({postType: 'announcement', skip: 0, limit: 3}).then(
				(res) => {
					setAnouncements([...res.data]);
				},
				() => {},
			),
			Feed.getAllFeed({postType: 'article', skip: 0, limit: 3}).then(
				(res) => {
					setArticles([...res.data]);
				},
				() => {},
			),
			Feed.getAllFeed({postType: 'job', skip: 0, limit: 3}).then(
				(res) => {
					setJobs([...res.data]);
				},
				() => {},
			),
		]).then(
			() => setIsLoading(false),
			() => {
				setIsLoading(false);
			},
		);
	};

	React.useEffect(() => {
		getAlldata();
	}, []);

	return (
		<>
			<Conatiner>
				<TitleContainer>
					<H1>Manage Posts</H1>
				</TitleContainer>
				<CreatePostContainer>
					<div>
						<SearchInput
							type="search"
							id="search"
							placeholder="Search"
						/>
						<div>
							<SearchIconImage src={SearchNew} alt="search" />
							<CloseIconImage src={CloseIcon1} alt="search" />
						</div>
					</div>
					<div>
						<CreatePostButton onClick={() => setModal(true)}>
							Create New Post
						</CreatePostButton>
					</div>
				</CreatePostContainer>
				{!isLoading ? (
					<>
						<MasterGridContainer>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
								<Text>Announcement</Text>
								<div
									style={{padding: 20, display: 'flex'}}
									onClick={() =>
										history.push(
											'/manage-post/announcement',
										)
									}>
									<div>
										<img
											style={{
												cursor: 'pointer',
												width: 86,
											}}
											src={SeeAll}
											alt="forward"></img>
									</div>
								</div>
							</FlexRow>
							<GridContainer>
								{anouncements.map((item) => (
									<PostCard
										item={item}
										key={item._id}
										setData={setAnouncements}
									/>
								))}
							</GridContainer>
						</MasterGridContainer>
						<MasterGridContainer>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
								<Text>Event</Text>
								<div
									style={{padding: 20, display: 'flex'}}
									onClick={() =>
										history.push('/manage-post/events')
									}>
									<img
										style={{
											cursor: 'pointer',
											width: 86,
										}}
										src={SeeAll}
										alt="forward"
									/>
								</div>
							</FlexRow>
							<GridContainer>
								{events.map((item) => (
									<PostCard
										item={item}
										key={item._id}
										setData={setEvents}
									/>
								))}
							</GridContainer>
						</MasterGridContainer>
						<MasterGridContainer>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
								<Text>Article</Text>
								<div
									style={{padding: 20, display: 'flex'}}
									onClick={() =>
										history.push('/manage-post/article')
									}>
									<img
										style={{
											cursor: 'pointer',
											width: 86,
										}}
										src={SeeAll}
										alt="forward"
									/>
								</div>
							</FlexRow>
							<GridContainer>
								{articles.map((item) => (
									<PostCard
										item={item}
										key={item._id}
										setData={setArticles}
									/>
								))}
							</GridContainer>
						</MasterGridContainer>
						<MasterGridContainer>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
								<Text>Job</Text>
								<div
									style={{padding: 20, display: 'flex'}}
									onClick={() =>
										history.push('/manage-post/job')
									}>
									<img
										style={{
											cursor: 'pointer',
											width: 86,
										}}
										src={SeeAll}
										alt="forward"
									/>
								</div>
							</FlexRow>
							<GridContainer>
								{jobs.map((item) => (
									<PostCard
										item={item}
										key={item._id}
										setData={setJobs}
									/>
								))}
							</GridContainer>
						</MasterGridContainer>
					</>
				) : (
					isLoading && (
						<LoaderContainer>
							<div className="loader" />
						</LoaderContainer>
					)
				)}
				{modalIsOpen && (
					<CreatePostModal
						closeModal={closeModal}
						setSelectedPostType={setSelectedPostType}
						handleCreateBtn={handleCreateBtn}
						modalIsOpen={modalIsOpen}
						selectedPostType={selectedPostType}
						Listitems={Listitems}
					/>
				)}
			</Conatiner>
		</>
	);
};

export default ManagePosts;
