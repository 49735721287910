import React, {useCallback} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';
import {useForm, Controller} from 'react-hook-form';
import {useDropzone} from 'react-dropzone';

import {
	H2,
	H6Opacity,
	Border,
	BrandLine,
	CustomButton,
	InputButton,
	H6Error,
	AesTrick,
	TitleContainer,
	H1,
} from '../../../helpers/global';
import {
	Conatiner,
	CreatePostBox,
	Header,
	BasicBody,
	Flex,
	Flex4,
	Flex6,
	FlexRow,
	InputBox,
	TextArea,
	Loader,
	CreatePostContainer,
	CreatePostButton,
	Manage,
	CreateP,
} from '../index.styled';

import MyDropzone from '../../../components/DropZone';
import MyPdfDropzone from '../../../components/PdfDropZone';
import Feed from '../../../services/feed';
import ManagePostsHeader from '../../../components/ManagePostsHeader';
import CreatePostModal from '../../../components/CreatePostModal';
import {Listitems} from '../../ManagePosts';
import ImagesConatiner from '../_components/ImagesContainer';
import PdfConatiner from '../_components/PdfContainer';
import moment from 'moment';
import {clean} from '../../../helpers/utils';
import IconForward from '../../../assets/images/NewBack1.png';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Events = () => {
	const {id} = useParams();
	const history = useHistory();
	const {state} = useLocation();
	const {
		register,
		formState: {errors},
		handleSubmit,
		setValue,
	} = useForm();

	const [files, setFiles] = React.useState([]);
	const [pdffiles, setPdfFiles] = React.useState([]);
	const [isFreeValues] = React.useState(['NO', 'YES']);
	const [isFreeVal, setIsFreeVal] = React.useState(false);
	const [modalIsOpen, setModal] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [selectedPostType, setSelectedPostType] = React.useState(0);
	const [dateValue, setDateValue] = React.useState(new Date());

	const closeModal = () => {
		setModal(false);
	};

	const remove = (removeIndex) => {
		const newImages = files.filter((item, index) => index !== removeIndex);
		setFiles(newImages);
	};

	const removePdf = (removeIndex) => {
		const newPdf = pdffiles.filter((item, index) => index !== removeIndex);
		setPdfFiles(newPdf);
	};

	const handleCreateBtn = () => {
		setModal(false);
		history.push(
			`/create-post/${Listitems[selectedPostType].name.toLowerCase()}`,
		);
	};

	const onSubmit = (data) => {
		if (data.website.length) {
			if (data.website.includes('https://')) {
				//return
			} else if (
				data.website.includes('http://') &&
				data.website.indexOf('http://') === 0
			) {
				let newStr = data.website;
				newStr.replace('http://', 'https://');
				data.website = newStr;
			} else if (data.website.indexOf('https://') !== 0) {
				const prefix = 'https://';
				data.website = prefix + data.website;
			}
		}

		if (dateValue) {
			const newDate = moment(dateValue).utc().format();
			//data.startDate = newDate;
			setIsLoading(true);
			const _images = files.map((x) => ({
				key: x.name,
				body: x.base64,
			}));
			const newCleanData = clean(data);
			const uploadData = {
				...newCleanData,
				postType: 'events',
				photo: _images,
				isFree: data.isFree === '0' ? false : true,
				price: data.isFree === '1' ? '0' : data.price,
				title: data.title,
				description: data.description,
				startDate: newDate,
			};
			Feed.uploadEditPosts(uploadData, state?.postID).then(
				(response) => {
					setIsLoading(false);
					history.push('/manage-post');
				},
				(err) => {
					setIsLoading(false);
					console.warn('ERROR LOG', err);
				},
			);
		}
	};

	const onDrop = (acceptedFiles) => {
		//console.log('props inside callback', props);
		if (files.length >= 4) {
			return;
		} else {
			let arrayNew = [...files];
			acceptedFiles.map((newFile) => {
				arrayNew.push(newFile);
			});

			setFiles([...arrayNew]);
			setFiles(
				arrayNew.map((file) => {
					let fileData;
					const fileObj = Object.assign(file, {
						body: URL.createObjectURL(file),
					});
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = (event) => {
						fileData = Object.assign(fileObj, {
							base64: event.target.result,
						});
					};
					return fileObj;
				}),
			);
		}
	};

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		accept: 'image/*',
		maxFiles: 4,
		onDrop: (acceptedFiles) => onDrop(acceptedFiles),
		onDropRejected: () => {
			setIsOpen(true);
		},
	});
	const handleIsFreeChange = (e) => {
		if (isFreeValues[e.target.value] === 'YES') {
			setIsFreeVal(true);
		} else {
			setIsFreeVal(false);
		}
	};

	// React.useEffect(() => {
	// 	files.forEach((file) => URL.revokeObjectURL(file.body)); //item.preview
	// }, [files]);

	React.useEffect(() => {
		if (state?.postID) {
			setIsLoading(true);
			Feed.getFeedbyId(state?.postID).then(
				(res) => {
					const details = res.data;
					const date = moment(
						details[details.postType].startDate,
					).format('YYYY-MM-DD[T]HH:mm');
					const isFree = details[details.postType]?.isFree;
					const website = details[details.postType]?.website;
					const photo = details[details.postType]?.photo;
					setValue('title', details[details.postType].title);
					setValue(
						'description',
						details[details.postType].description,
					);
					setValue('location', details[details.postType].location);
					setValue('startDate', date);
					setValue('isFree', isFree ? '1' : '0');
					setValue('price', details[details.postType].price);
					setValue('website', website ? website : undefined);
					setFiles(photo ? photo : []);
					setIsFreeVal(isFree);
					setIsLoading(false);
				},
				(err) => {
					console.log('ERR', err);
				},
			);
		}
	}, [state]);

	return (
		<Conatiner>
			<CreatePostContainer>
				<TitleContainer>
					<H1>Manage Posts</H1>
				</TitleContainer>
				<div style={{display: 'flex', paddingTop: 8}}>
					<Manage>Manage Post {'>'} </Manage> &nbsp;
					<CreateP> Create New Event Post</CreateP>
					{/* <CreatePostButton onClick={() => history.goBack()}>
						Back
					</CreatePostButton> */}
				</div>
			</CreatePostContainer>
			<form onSubmit={handleSubmit(onSubmit)}>
				<CreatePostBox>
					<div
						style={{
							display: 'inline-flex',
							width: '100%',
							justifyContent: 'space-between',
						}}>
						<Header>
							<H2
								style={{
									textTransform: 'capitalize',
									fontSize: 16,
									fontWeight: 500,
									color: 'black',
								}}>
								New {id} Post
							</H2>
						</Header>
						<div
							style={{padding: 20, display: 'flex'}}
							onClick={() => history.goBack()}>
							<div style={{cursor: 'pointer', paddingRight: 8}}>
								Back
							</div>
							<div>
								<img
									style={{cursor: 'pointer', width: 13}}
									src={IconForward}
									alt="forward"></img>
							</div>
						</div>
					</div>
					<Border />
					<BasicBody>
						{/* <H6Opacity>3/3: Post creation</H6Opacity>
						<BrandLine /> */}
						<Flex>
							<Flex4>
								<H2>Basic</H2>
							</Flex4>
							<Flex6>
								<FlexRow>
									<Flex>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>
												TITLE <AesTrick>*</AesTrick>
											</H6Opacity>
										</Flex4>
										<Flex6>
											<InputBox
												placeholder={'Add title'}
												name={'title'}
												{...register('title', {
													required: true,
												})}
											/>
											{errors.title && (
												<H6Error>
													Title is required!
												</H6Error>
											)}
										</Flex6>
									</Flex>
								</FlexRow>
								<FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>
												DESCRIPTION{' '}
												<AesTrick>*</AesTrick>
											</H6Opacity>
										</Flex4>
										<Flex6>
											<TextArea
												placeholder={'Add description'}
												name={'description'}
												{...register('description', {
													required: true,
												})}
											/>
											{errors.description && (
												<H6Error>
													Description is required!
												</H6Error>
											)}
										</Flex6>
									</Flex>
								</FlexRow>
								<FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>
												LOCATION <AesTrick>*</AesTrick>
											</H6Opacity>
										</Flex4>
										<Flex6>
											<InputBox
												placeholder={'Add location'}
												name={'location'}
												{...register('location', {
													required: true,
												})}
											/>
											{errors.location && (
												<H6Error>
													Location is required!
												</H6Error>
											)}
										</Flex6>
									</Flex>
								</FlexRow>
								<FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>
												DATE & TIME{' '}
												<AesTrick>*</AesTrick>
											</H6Opacity>
										</Flex4>
										<Flex6>
											<ReactDatePicker
												showTimeSelect
												selected={dateValue}
												onChange={(data) =>
													setDateValue(data)
												}
												minDate={new Date()}
												//className="date"
												// dateFormat="yyyy/MM/dd"
												dateFormat="yyyy-MM-dd hh:mm"
											/>
											{!dateValue && (
												<H6Error>
													Date & Time is required!
												</H6Error>
											)}
										</Flex6>
									</Flex>
								</FlexRow>
								<FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>
												FREE <AesTrick>*</AesTrick>
											</H6Opacity>
										</Flex4>
										<Flex6>
											<select
												name="free"
												id="free"
												{...register('isFree', {
													required: true,
												})}
												onChange={(e) =>
													handleIsFreeChange(e)
												}>
												{isFreeValues.map(
													(item, key) => (
														<option
															value={key}
															key={key}>
															{item}
														</option>
													),
												)}
											</select>
										</Flex6>
									</Flex>
								</FlexRow>
								{!isFreeVal && (
									<FlexRow>
										<Flex style={{marginTop: 20}}>
											<Flex4 style={{flex: 0.2}}>
												<H6Opacity>
													PRICE <AesTrick>*</AesTrick>
												</H6Opacity>
											</Flex4>
											<Flex6>
												<InputBox
													placeholder={'Add price'}
													name={'price'}
													{...register('price', {
														required: isFreeVal
															? false
															: true,
													})}
												/>
												{errors.price && (
													<H6Error>
														Price is required!
													</H6Error>
												)}
											</Flex6>
										</Flex>
									</FlexRow>
								)}
							</Flex6>
						</Flex>
					</BasicBody>
					<Border />
					<BasicBody>
						<Flex>
							<Flex4>
								<H2>Attachments</H2>
							</Flex4>
							<Flex6>
								<FlexRow>
									<Flex>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>LINK</H6Opacity>
										</Flex4>
										<Flex6>
											<InputBox
												placeholder={'Add website link'}
												{...register('website', {
													required: false,
												})}
											/>
										</Flex6>
									</Flex>
								</FlexRow>
								{/* <FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>ATTACH PDF </H6Opacity>
										</Flex4>
										<Flex6>
											{pdffiles?.length ? (
												<PdfConatiner
													files={pdffiles}
													remove={removePdf}
												/>
											) : (
												<MyPdfDropzone
													setPdfFiles={setPdfFiles}
												/>
											)}
										</Flex6>
									</Flex>
								</FlexRow> */}
								<FlexRow>
									<Flex style={{marginTop: 20}}>
										<Flex4 style={{flex: 0.2}}>
											<H6Opacity>ADD PHOTOS </H6Opacity>
										</Flex4>
										<Flex6>
											<div
												{...getRootProps()}
												style={{
													backgroundColor: '#f7f8fb',
													borderRadius: 5,
													padding: 20,
													cursor: 'pointer',
													borderStyle: 'dashed',
													borderWidth: 1,
												}}>
												<input {...getInputProps()} />
												{isDragActive ? (
													<p>
														Drop the files here ...
													</p>
												) : (
													<>
														<p>
															Drag and drop some
															files here, or click
															to select files
														</p>
														<em>
															(You can upload upto
															4 files)
														</em>
													</>
												)}
											</div>
										</Flex6>
									</Flex>
								</FlexRow>
							</Flex6>
						</Flex>
					</BasicBody>
					<Border />
					<div style={{padding: 20}}>
						{files.length ? (
							<ImagesConatiner files={files} remove={remove} />
						) : (
							<H2>No Photos attached</H2>
						)}
					</div>
					<Border />
					<Flex
						style={{
							justifyContent: 'space-between',
							padding: 20,
						}}>
						<CustomButton
							style={{
								backgroundColor: '#eae9fa',
								color: '#9892E4',
								fontWeight: 700,
							}}
							onClick={() => history.push('/manage-post')}>
							Cancel
						</CustomButton>
						<InputButton
							style={{
								backgroundColor: '#ffd855',
								color: '#FFFFFF',
								fontWeight: 700,
								fontSize: 14,
							}}
							type={'submit'}
							value={'Post'}
						/>
					</Flex>
				</CreatePostBox>
			</form>
			{modalIsOpen && (
				<CreatePostModal
					closeModal={closeModal}
					setSelectedPostType={setSelectedPostType}
					handleCreateBtn={handleCreateBtn}
					modalIsOpen={modalIsOpen}
					selectedPostType={selectedPostType}
					Listitems={Listitems}
				/>
			)}
			{isLoading && (
				<Loader>
					<div className="loader" />
				</Loader>
			)}
		</Conatiner>
	);
};

export default Events;
