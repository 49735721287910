import styled from 'styled-components';

export const DocConatiner = styled.div`
	margin: 20px;
`;

export const BoxItems = styled.div`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	row-gap: 10px;
	column-gap: 10px;
	padding: 20px;
`;

export const Image = styled.img`
	width: 50px;
`;

export const FileContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 1px solid #9892e4;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 127px;
	height: 187px;
`;
