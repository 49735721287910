import styled from 'styled-components';

export const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
	position: relative;
`;

export const CreatePostBox = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 23px;
	margin-top: 20px;
`;

export const Header = styled.div`
	padding: 20px;
`;

export const BasicBody = styled.div`
	padding: 20px;
`;

export const Flex = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
`;

export const Flex4 = styled.div`
	flex: 0.4;
`;

export const Flex6 = styled.div`
	flex: 0.6;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const InputBox = styled.input`
	padding: 15px;
	background-color: #f7f8fb;
	outline: 0;
	border: 0;
	border-radius: 5px;
`;

export const TextArea = styled.textarea`
	padding: 10px;
	background-color: #f7f8fb;
	outline: 0;
	border: 0;
	border-radius: 5px;
	height: 200px;
	resize: none;
`;

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

export const Image = styled.img`
	width: 100px;
	height: 100px;
	margin: 5px;
	border-radius: 10px;
`;

export const ImageWrap = styled.div`
	position: relative;
`;

export const Close = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	background-color: #9696bc;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	> svg path {
		stroke: #ffffff;
	}
`;

export const Loader = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	bottom: 0;
`;


export const CreatePostContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
`;

export const CreatePostButton = styled.div`
	padding: 12px;
	background-color: #ffdd55;
	border-radius: 26px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-weight: bold;
	font-size: 14px;
`;

export const DateBox = styled.div`
	padding: 15px;
	background-color: #f7f8fb;
	outline: 0;
	border: 0;
	border-radius: 5px;
`;