import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Box, H1, H2, TitleContainer} from '../../../helpers/global';
import {DocConatiner, BoxItems, Image, FileContainer} from './index.styled';
import Folder from '../../../assets/images/folder.png';
import Document from '../../../services/document';
import {Loader} from '../../../components/Event/index.styled';
import {LoaderContainer} from '../../AllPosts/index.styled';

const DocumentTypes = () => {
	const [docSections, setDocSections] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getDocumentSections = () => {
			setIsLoading(true);
			Document.getDocumentSections().then(
				(res) => {
					setDocSections(res.data);
					setIsLoading(false);
				},
				() => {
					setDocSections([]);
					setIsLoading(false);
				},
			);
		};
		getDocumentSections();
	}, []);

	return (
		<DocConatiner>
			<TitleContainer>
				<H1>Document Storage</H1>
			</TitleContainer>
			<Box>
				{isLoading ? (
					<LoaderContainer isLoading={isLoading}>
						<div className="loader" />
					</LoaderContainer>
				) : (
					<BoxItems>
						{docSections.map((item) => (
							<NavLink
								key={item._id}
								to={`/documents/${item._id}`}
								style={{textDecoration: 'none'}}>
								<FileContainer>
									<Image src={Folder} alt="folder" />
									<H2 style={{textAlign: 'center'}}>
										{item.name}
									</H2>
								</FileContainer>
							</NavLink>
						))}
					</BoxItems>
				)}
			</Box>
		</DocConatiner>
	);
};

export default DocumentTypes;
