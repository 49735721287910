import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import Home from '../container/Home';
import Users from '../container/Users';
import ManagePosts from '../container/ManagePosts';
import AdminRoute from './AdminRoute';
import CreatePost from '../container/CreatePost';
import Settings from '../container/Settings';
import Help from '../container/Help';
import UserDetails from '../container/UserDetails';
import AllPosts from '../container/AllPosts';
import EventPost from '../components/Event';
import EditPost from '../container/EditPost';
import AnnouncementPost from '../components/Announcement';
import ArticlePost from '../components/Articles';
import JobPost from '../components/Job';
import DocumentStorage from '../container/DocumentStorage';
import DocumentTypes from '../container/DocumentStorage/DocumentTypes';
// import SignIn from '../container/UserAuth/SignIn';

const Routes = (props) => {
	return (
		<Switch>
			<AdminRoute path={'/'} component={Home} exact />
			{/* <Route path={'/login'} component={SignIn} /> */}
			<AdminRoute path={'/manage-post'} component={ManagePosts} exact />
			<AdminRoute path={'/manage-post/:id'} component={AllPosts} exact />
			<AdminRoute
				path={'/manage-post/event/:id'}
				component={EventPost}
				exact
			/>
			<AdminRoute
				path={'/manage-post/announcement/:id'}
				component={AnnouncementPost}
				exact
			/>
			<AdminRoute
				path={'/manage-post/article/:id'}
				component={ArticlePost}
				exact
			/>
			<AdminRoute
				path={'/manage-post/job/:id'}
				component={JobPost}
				exact
			/>
			<AdminRoute path={'/users'} component={Users} exact />
			<AdminRoute path={'/users/:id'} component={UserDetails} exact />
			<AdminRoute path={'/create-post/:id'} component={CreatePost} />
			<AdminRoute path={'/edit-post/:id'} component={EditPost} />
			<AdminRoute path={'/settings'} component={Settings} />
			<AdminRoute path={'/help'} component={Help} />
			<AdminRoute path={'/documents'} component={DocumentTypes} exact />
			<AdminRoute
				path={'/documents/:id'}
				component={DocumentStorage}
				exact
			/>
		</Switch>
	);
};

export default Routes;
