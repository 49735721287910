import React from 'react';
import {useParams} from 'react-router';
import Announcements from './Announcement';
import Articles from './Articles';
import Events from './Events';
import Jobs from './Jobs';

const CreatePost = () => {
	let {id} = useParams();
	console.log('id',id);

	if (id === 'announcement') {
		return <Announcements />;
	} else if (id === 'event') {
		return <Events />;
	} else if (id === 'article') {
		return <Articles />;
	} else if (id === 'job') {
		return <Jobs />;
	} else {
		return <div>UNKNOWN</div>;
	}
};

export default CreatePost;
