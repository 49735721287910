import React from 'react';
import PropTypes from 'prop-types';
import {StatusColor} from '../index.styled';

const Status = ({ps}) => {
	if (ps === 20) {
		return <StatusColor color={'#989aa1'}>Pending</StatusColor>;
	} else if(ps === 30) {
		return <StatusColor color={'#8CD7B6'}>Approved</StatusColor>;
	}else if(ps === 40) {
		return <StatusColor color={'#F684BB'}>Rejected</StatusColor>;
	}
	else if (ps === 50) {
		return <StatusColor color={'#EE8733'}>Renew</StatusColor>;
	}else {
		return;
	}
};

Status.propTypes = {
	ps: PropTypes.number.isRequired,
};

export default Status;
