import * as axios from '../network';
import config from '../config/apiconfig';

class Document {
	static getDocumentSections() {
		return axios.appRequest({
			method: axios.GET,
			url: config.document.documentSections,
		});
	}

	static getDocuments(id) {
		return axios.appRequest({
			method: axios.GET,
			url: config.document.alldocuments + '?documentSection=' + id,
		});
	}

	static updateisViewable(id, value) {
		return axios.appRequest({
			method: axios.PATCH,
			url: config.document.alldocuments + id + '/isViewable',
			data: {
				isViewable: value,
			},
		});
	}

	static uploadDocument(inData) {
		return axios.appRequest({
			method: axios.POST,
			url: 'v1/documents/',
			data: inData,
			headers: {'Content-Type': 'multipart/form-data'},
		});
	}

	static deleteDocument(id) {
		return axios.appRequest({
			method: axios.DELETE,
			url: config.document.alldocuments + id,
		});
	}
}

export default Document;
