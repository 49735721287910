import * as axios from '../network';

class Events {
	static getAttendees(postID, params) {
		const queryString = Object.keys(params)
			.map((key) => key + '=' + params[key])
			.join('&');

		return axios.appRequest({
			method: axios.GET,
			url: '/posts/' + postID + '/' + 'attendieeList?' + queryString,
		});
	}
}

export default Events;
