export class Keys {
	static authorizationTokenKey = 'user_authorization_token';
	static refreshTokenKey = 'user_refresh_token';
	static currLoc = 'currentLocation';
	static settingsDataKey = 'settings_profile_data';
	static userTypeKey = 'user_type';
	static userLoggedInKey = 'user_logged_in';
	static userId = 'user_id';
	static userLoggedOutKey = 'user_logged_out';
	static currLangCode = 'lang_code';
	static userId = 'user_id';
	static userData = 'user_data';
	static roleCodes = 'role_code';
	static nameLength = '140';
	static descLength = '1024';
	static longTextLength = '2048';
	static appBaseName = '/';
	static roleRights = 'role_rights';

	static adminRole = 10;
}
