import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto;
	// flex-wrap: wrap;
	// grid-template-columns: 100px 100px 100px;
	// grid-template-rows: 100px auto 100px;
	column-gap: 15px;
	background: white;
	border-radius: 20px;
	margin: 45px;
`;

export const ComingSoon = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 700;
	font-size: 30px;
`;

export const Text = styled.div`
	display: flex;
	align-self: center;
	font-weight: 400;
	font-size: 18px;
`;

export const TextEllipsis = styled(Text)`
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 200;
	overflow: hidden;
`;

export const BottomContainer = styled.div`
	display: flex;
	align-item: flex-end;
	justify-content: space-between;
	padding: 20px 10px;
`;

export const GridItems = styled.div`
	display: grid;
	grid-template-columns: 1.8fr 1.4fr 0.8fr 0.5fr 1.2fr;
	column-gap: 10px;
	padding: 10px 10px;
	.bold {
		font-weight: bold;
	}
	cursor: pointer;
	border-bottom: ${({showBorder}) => (showBorder ? '1px solid #eae9fa' : '')};
	&:hover {
		background-color: ${({showHoverEffect}) =>
			showHoverEffect ? '#eae9fa' : ''};
	}
`;

export const Back = styled.img`
	cursor: pointer;
	width: 13px;
	align-self: center;
	transform: rotate(180deg);
	margin-right: 10px;
`;

export const PDFIcon = styled.img`
	margin: 0px 15px 0px 0px;
	height: 25px;
`;

export const DocIcon = styled.img`
	margin: 0px 15px 0px 0px;
	height: 25px;
`;

export const ImageIcon = styled.img`
	margin: 0px 15px 0px 0px;
	height: 25px;
`;

export const DocumentNameWrapper = styled.div`
	display: flex;
	max-width: 250px;
	align-items: center;
`;

export const NoDocumentsContainer = styled.div`
	display: flex;
	padding: 50px;
	align-items: center;
	justify-content: center;
`;

export const WrapIcons = styled.div`
	display: flex;
	justify-content: space-between;
`;
