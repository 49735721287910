import React from 'react';
import PropTypes from 'prop-types';
import {Image, ImageWrap, ImageWrapper, Close} from '../index.styled';
import {ReactComponent as CloseSvg} from '../../../assets/images/close.svg';

const ImagesConatiner = ({files, remove}) => {
	return (
		<ImageWrapper>
			{files.map((item, index) => (
				<ImageWrap key={index}>
					<Image src={item.body} />
					<Close onClick={() => remove(index)}>
						<CloseSvg />
					</Close>
				</ImageWrap>
			))}
		</ImageWrapper>
	);
};

ImagesConatiner.propTypes = {
	files: PropTypes.array.isRequired,
	remove: PropTypes.func.isRequired,
};

export default ImagesConatiner;
