import React from 'react';
import {useParams} from 'react-router';
import Announcements from './Announcements';
import Articles from './Articles';
import Events from './Events';
import Jobs from './Jobs';

const EditPost = (props) => {
	let {id} = useParams();

	if (id === 'announcement') {
		return <Announcements />;
	} else if (id === 'events') {
		return <Events />;
	} else if (id === 'article') {
		return <Articles />;
	} else if (id === 'job') {
		return <Jobs />;
	} else {
		return <div>UNKNOWN</div>;
	}
};

export default EditPost;
