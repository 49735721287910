import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
	View,
	ModalTitleContainer,
	PostTypeText,
	PostItem,
	BtnContainer,
	Border,
} from './index.styled';
import {Button} from '../../helpers/global';
import {ReactComponent as Close} from '../../assets/images/close.svg';

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 10,
		padding: 0,
	},
};

const CreatePostModal = ({
	closeModal,
	setSelectedPostType,
	handleCreateBtn,
	modalIsOpen,
	selectedPostType,
	Listitems,
}) => {
	return (
		<Modal
			isOpen={modalIsOpen}
			// onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			ariaHideApp={false}
			contentLabel="Example Modal">
			<View>
				<ModalTitleContainer>
					<PostTypeText>Select Post Type</PostTypeText>
					<div
						style={{cursor: 'pointer'}}
						onClick={() => closeModal()}>
						<Close />
					</div>
				</ModalTitleContainer>
				<>
					{Listitems.map((item, index) => (
						<React.Fragment key={index}>
							<Border />
							<PostItem
								selected={index === selectedPostType}
								onClick={() => setSelectedPostType(index)}>
								{item.name}
							</PostItem>
						</React.Fragment>
					))}
					<Border />
				</>
				<BtnContainer>
					<Button onClick={() => handleCreateBtn()}>Create</Button>
				</BtnContainer>
			</View>
		</Modal>
	);
};

CreatePostModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	setSelectedPostType: PropTypes.func.isRequired,
	handleCreateBtn: PropTypes.func.isRequired,
	modalIsOpen: PropTypes.bool.isRequired,
	selectedPostType: PropTypes.string.isRequired,
	Listitems: PropTypes.array.isRequired,
};

export default CreatePostModal;
