import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {
	Container,
	UsersListContainer,
	TableHeader,
	LoaderContainer,
	Flex02,
	Flex01,
	Flex025,
	StatsContainer,
	TabContainer,
	TabLabel,
	BulkApprovalContainer,
	CheckBox,
	PaginationWrapper,
	SmallProfile,
	ProfileWrapper,
	Search,
	Spacing,
	FlexNeww,
	Results,
	HeaderNew,
	SearchInput,
} from './index.styled';
import {
	TitleContainer,
	H1,
	H2,
	Border,
	CustomButton,
} from '../../helpers/global';
import User from '../../services/users';
import Status from './components/Status';
import {ReactComponent as UserSvg} from '../../assets/images/user.svg';
import SearchIcon from '../../assets/images/menu-search.png';
import SearchIcon1 from '../../assets/images/SearchIcon1.png';
import vector from '../../assets/images/Vector(1).png';
import CloseSvg from '../../assets/images/close.svg';
import CloseIcon from '../../assets/images/closeIcon.png';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import moment from 'moment';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FlexNew} from '../UserDetails/index.styled';
import {Table} from 'reactstrap';

const Tabs = [
	{key: 'ALL_USERS', name: 'All Users'},
	{key: 'PENDING_USERS', name: 'Pending Approvals'},
	{key: 'RENEWAL_USERS', name: 'Pending Renewals'},
];

const Users = (props) => {
	const history = useHistory();
	const [activeTab, setActiveTab] = React.useState(0);
	const [allUsers, setAllUsers] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [checkedState, setCheckedState] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(1);

	const [selectedItems, setSelectedItem] = React.useState([]);
	const [activeResidentsCount, setActiveResidentsCount] = React.useState(0);
	const [pendingRequestCount, setPendingRequestCount] = React.useState(0);
	const [pendingRenewalCount, setPendingRenewalCount] = React.useState(0);
	const [approveFlag, setApproveFlag] = React.useState(false);
	const [rejectFlag, setRejectFlag] = React.useState(false);
	const [profileStatus, setProfileStatus] = React.useState(null);
	const [searchText, setSearchText] = React.useState('');

	const handleOnChange = (position) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item,
		);

		setCheckedState(updatedCheckedState);
		const itemsSelected = updatedCheckedState
			.map((item, index) => {
				if (item === true) {
					return allUsers[index];
				}
			})
			.filter((item) => item);

		setSelectedItem(itemsSelected);
	};

	const handleOnAllSelect = () => {
		let updatedCheckedState = checkedState.map((item, index) => {
			return item === false ? true : item === true ? false : false;
		});
		setCheckedState(updatedCheckedState);
		const itemsSelected = updatedCheckedState.map((item, index) => {
			if (item === true) {
				return allUsers[index];
			} else {
				return;
			}
		});
		//.filter((item) => item);

		setSelectedItem(itemsSelected);
	};

	const handlePageClick = async (page, pageSize) => {
		setCurrentPage(page);
		setIsLoading(true);
		setSearchText('');
		const params = activeTab
			? {
					skip: page * pageSize - 10,
					limit: pageSize,
					profileStatus: activeTab === 1 ? 20 : 50,
			  }
			: {
					skip: page * pageSize - 10,
					limit: pageSize,
			  };
		await User.getUsers(params).then(
			(res) => {
				if (res.meta.code === 201) {
					setAllUsers([]);
				} else {
					setAllUsers(res.data);
					setCheckedState(new Array(res.data.length).fill(false));
				}
				setIsLoading(false);
			},
			(err) => {
				setIsLoading(false);
				console.warn(err);
			},
		);
	};
	const handleApproveUsers = async () => {
		setIsLoading(true);
		setProfileStatus(30);
		let newIds = [];
		if (selectedItems.length) {
			setApproveFlag(true);
			setRejectFlag(false);
			selectedItems.map((item) => {
				newIds.push(item._id);
			});
			const params = {
				users: newIds,
				profileStatus: 30,
			};
			await User.bulkApprove(params).then(
				(res) => {
					handlePageClick(currentPage, 10);
					setIsLoading(false);
					toast('Approved', {
						position: 'top-center',
						autoClose: 2000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				},
				(err) => {
					setIsLoading(false);
					console.warn(err);
				},
			);

			await User.getUserCount().then(
				(res) => {
					//setIsLoading(false);
					setTotalCount(res.pagination.totalCount);
					res.data.map((usercount) => {
						if (usercount.profileStatus === 30) {
							setActiveResidentsCount(usercount.count);
						} else if (usercount.profileStatus === 20) {
							if(pendingRequestCount == 1){
								setPendingRequestCount(0);
							}else {
								setPendingRequestCount(usercount.count);
							}
							
						} else if (usercount.profileStatus === 50) {
							setPendingRenewalCount(usercount.count);
						} else {
							return;
						}
					});
				},
				(err) => {
					//setIsLoading(false);
					console.warn(err);
				},
			);
		} else {
			setIsLoading(false);
			toast('Please select atleast one record', {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};
	const handleRejectUsers = async () => {
		setProfileStatus(40);
		let newIds = [];
		if (selectedItems.length) {
			setApproveFlag(false);
			setRejectFlag(true);
			selectedItems.map((item) => {
				newIds.push(item._id);
			});
			const params = {
				users: newIds,
				profileStatus: 40,
			};
			await User.bulkReject(params).then(
				(res) => {
					handlePageClick(currentPage, 10);
					toast('Rejected', {
						position: 'top-center',
						autoClose: 2000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				},
				(err) => {
					setIsLoading(false);
					console.warn(err);
				},
			);
			await User.getUserCount().then(
				(res) => {
					//setIsLoading(false);
					setTotalCount(res.pagination.totalCount);
					res.data.map((usercount) => {
						if (usercount.profileStatus === 30) {
							setActiveResidentsCount(usercount.count);
						} else if (usercount.profileStatus === 20) {
							setPendingRequestCount(usercount.count);
						} else if (usercount.profileStatus === 50) {
							setPendingRenewalCount(usercount.count);
						} else {
							return;
						}
					});
				},
				(err) => {
					//setIsLoading(false);
					console.warn(err);
				},
			);
		} else {
			setIsLoading(false);
			toast('Please select atleast one record', {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};
	useEffect(async () => {
		setIsLoading(true);
		const params = activeTab
			? {
					skip: 0,
					limit: 10,
					profileStatus: activeTab === 1 ? 20 : 50,
			  }
			: {
					skip: 0,
					limit: 10,
			  };
		await User.getUsers(params).then(
			(res) => {
				setIsLoading(false);
				if (res.meta.code === 201) {
					setAllUsers([]);
				} else {
					setAllUsers(res.data);
					setCheckedState(new Array(res.data.length).fill(false));
				}
			},
			(err) => {
				setIsLoading(false);
				console.warn(err);
			},
		);

		await User.getUserCount().then(
			(res) => {
				setIsLoading(false);
				setTotalCount(res.pagination.totalCount);
				res.data.map((usercount) => {
					if (usercount.profileStatus === 30) {
						setActiveResidentsCount(usercount.count);
					} else if (usercount.profileStatus === 20) {
						setPendingRequestCount(usercount.count);
					} else if (usercount.profileStatus === 50) {
						setPendingRenewalCount(usercount.count);
					} else {
						return;
					}
				});
			},
			(err) => {
				setIsLoading(false);
				console.warn(err);
			},
		);
	}, [activeTab]);

	const handleTabClick = (id) => {
		history.push(`/users/${id}`);
	};

	const handleSearch = async (data) => {
		setIsLoading(true);

		if (data === '' || data === undefined) {
			setIsLoading(true);
			const params = activeTab
				? {
						skip: 0,
						limit: 10,
						profileStatus: activeTab === 1 ? 20 : 50,
				  }
				: {
						skip: 0,
						limit: 10,
				  };
			await User.getUsers(params).then(
				(res) => {
					setIsLoading(false);
					setAllUsers(res.data);
					//setTotalCount(res.pagination.totalCount);
					setCheckedState(new Array(res.data.length).fill(false));
				},
				(err) => {
					setIsLoading(false);
					console.warn(err);
				},
			);
		} else {
			const paramsNew = {
				skip: 0,
				limit: 10,
				name: data,
				profileStatus: activeTab === 1 ? 20 : activeTab === 2 ? 50 : ''
			};

			await User.getUserByUniversity(paramsNew).then(
				(res) => {
					if (res.meta.code === 200) {
						setIsLoading(false);
						setAllUsers(res.data);
						//setTotalCount(res.pagination.totalCount);
						setCheckedState(new Array(res.data.length).fill(false));
					} else if (res.meta.code === 201) {
						setIsLoading(false);
						setAllUsers([]);
					}
				},
				(err) => {
					setIsLoading(false);
					console.warn(err);
				},
			);
		}
	};

	return (
		<Container>
			<TitleContainer>
				<H1 style={{marginLeft: 10}}>Users</H1>
				{/* <CustomButton
					style={{
						backgroundColor: '#fff',
					}}
					//onClick={() => history.goBack()}
				>
					<img style={{paddingRight: 5}} src={vector}></img>Last
				</CustomButton> */}
			</TitleContainer>
			<StatsContainer>
				<Flex025>
					<H2>Total Residents</H2>
					<H1 style={{fontWeight: 700, fontSize: 30}}>
						{totalCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Active Residents</H2>
					<H1
						style={{
							color: '#A9E1C8',
							fontWeight: 700,
							fontSize: 30,
						}}>
						{activeResidentsCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Pending Approval</H2>
					<H1
						style={{
							color: '#B9B9BE',
							fontWeight: 700,
							fontSize: 30,
						}}>
						{pendingRequestCount}
					</H1>
				</Flex025>
				<Flex025>
					<H2>Pending Renewal</H2>
					<H1 style={{color: '#FFC188'}}>{pendingRenewalCount}</H1>
				</Flex025>
			</StatsContainer>
			<TabContainer>
				{Tabs.map((item, index) => (
					<TabLabel
						onClick={() => {
							setCurrentPage(1);
							setActiveTab(index);
							setSearchText('');
						}}
						key={index}
						active={activeTab === index}>
						{item.name}
					</TabLabel>
				))}
			</TabContainer>

			<UsersListContainer>
				<div>
					<BulkApprovalContainer>
						<Spacing>
							{/* <Search> */}
							<div style={{marginTop: 20, marginLeft: 10}}>
								<div className="container-2">
									<div>
										<SearchInput
											style={{
												position: 'absolute',
											}}
											onChange={(e) => {
												handleSearch(e.target.value);
												setSearchText(e.target.value);
											}}
											value={searchText}
											type="search"
											id="search"
											placeholder="Search"
										/>
										<div>
											<img
												style={{
													position: 'relative',
													right: 12,
												}}
												src={SearchIcon}
												alt="search"></img>
											<img
												onClick={() => {
													if (searchText !== '') {
														handleSearch();
														setSearchText('');
													} else {
														return;
													}
												}}
												style={{
													position: 'relative',
													left: 260,
													width: 20,
												}}
												src={SearchIcon1}
												alt="search"></img>
										</div>
									</div>
								</div>
							</div>
							{/* </Search> */}
							<div></div>
							<BulkApprovalContainer>
								<CustomButton
									onClick={handleApproveUsers}
									style={{
										backgroundColor: '#FFD855',
										color: '#fff',
										opacity:
											selectedItems &&
											selectedItems.length
												? 1
												: 0.3,
										marginLeft: 10,
										marginRight: 10,
										fontWeight: 700,
									}}>
									Approve Selected
								</CustomButton>
								<CustomButton
									onClick={handleRejectUsers}
									style={{
										backgroundColor: '#EE8733',
										color: '#fff',
										opacity:
											selectedItems &&
											selectedItems.length
												? 1
												: 0.3,
										marginLeft: 10,
										marginRight: 10,
										fontWeight: 700,
									}}>
									Reject Selected
								</CustomButton>
							</BulkApprovalContainer>
						</Spacing>
					</BulkApprovalContainer>

					<div>
						{allUsers && allUsers.length && !isLoading ? (
							<Table responsive size="sm">
								<thead>
									<tr>
										<th>
											{/* <CheckBox
										type="checkbox"
										//checked={checkedState[0]}
										onChange={() => handleOnAllSelect()}
									/> */}
										</th>
										<th></th>
										<th>Resident Name</th>
										<th>Email Address</th>
										<th>Dep. Date</th>
										<th>Room Type</th>
										<th>Bed No.</th>
										<th>Mobile No.</th>
										{/* <th>RoomType</th> */}

										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{allUsers.map((item, index) => (
										<tr
											style={{cursor: 'pointer'}}
											key={index}
											onClick={() =>
												handleTabClick(item._id)
											}>
											<td>
												<Flex01
													style={{flex: 0.05}}
													onClick={(event) => {
														event.stopPropagation();
													}}>
													{item.profileStatus ===
													20 ? (
														<CheckBox>
															<div className="custom-control custom-checkbox">
																<input
																	style={{
																		height: 40,
																	}}
																	type="checkbox"
																	id={`custom-checkbox-${index}`}
																	//name={props.name}
																	className="custom-control-input"
																	checked={
																		checkedState[
																			index
																		]
																	}
																	onChange={() =>
																		handleOnChange(
																			index,
																		)
																	}
																/>
																<label
																	htmlFor={`custom-checkbox-${index}`}
																	className="custom-control-label"></label>
															</div>
															{/* <input
																	className="custom-control-input"
																	type="checkbox"
																	id={`custom-checkbox-${index}`}
																	checked={
																		checkedState[
																			index
																		]
																	}
																	onChange={() =>
																		handleOnChange(
																			index,
																		)
																	}></input> */}
														</CheckBox>
													) : (
														''
													)}
												</Flex01>
											</td>
											<td>
												{item.profilePic?.body ? (
													<ProfileWrapper>
														<SmallProfile
															src={
																item.profilePic
																	?.body
															}
															style={{
																objectFit:
																	'cover',
															}}
															alt="img"
														/>
													</ProfileWrapper>
												) : (
													<ProfileWrapper>
														<UserSvg />
													</ProfileWrapper>
												)}
											</td>
											<td
												style={{
													fontSize: 14,
													minWidth: 140,
												}}>
												{item.firstName +
													' ' +
													item.lastName}
											</td>
											<td
												style={{
													fontSize: 14,
													minWidth: 150,
													wordBreak: 'break-word',
												}}>
												{item.email}
											</td>
											<td
												style={{
													fontSize: 14,
													minWidth: 120,
												}}>
												{moment(item.departureDate)
													.format('DD-MMM-YYYY')
													.replace('-', ' ')
													.replace('-', ' ')}
											</td>
											<td
												style={{
													minWidth: 120,
													fontSize: 14,
												}}>
												{item.roomType}
											</td>
											<td
												style={{
													minWidth: 100,
													fontSize: 14,
												}}>
												{item.roomNumber}
											</td>
											<td style={{fontSize: 14}}>
												{'+' +
													item.phoneCode +
													' ' +
													item.phone}
											</td>
											{/* <td>
										{item.roomType + ' ' + item.roomNumber}
									</td> */}

											<td style={{fontSize: 14}}>
												<Status
													ps={item.profileStatus}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						) : isLoading ? (
							<LoaderContainer>
								<div className="loader" />
							</LoaderContainer>
						) : !allUsers.length ? (
							<FlexNeww>No Records Found</FlexNeww>
						) : (
							''
						)}
					</div>
				</div>

				{allUsers && allUsers.length ? (
					<HeaderNew>
						<Results>
							<div style={{display: 'flex'}}>
								<div style={{padding: 10}}>Results:</div>
								<CustomButton
									style={{
										backgroundColor: '#F7F8FB',
										width: 80,
										height: 40,
										color: '#989AA1',
									}}
									//onClick={() => history.goBack()}
								>
									1-10
								</CustomButton>
								{activeTab === 1 ? (
									<div style={{padding: 10}}>
										of {pendingRequestCount}
									</div>
								) : activeTab === 2 ? (
									<div style={{padding: 10}}>
										of {pendingRenewalCount}
									</div>
								) : (
									<div style={{padding: 10}}>
										of {totalCount}
									</div>
								)}
								{/* <div style={{padding: 10}}>of {totalCount}</div> */}
							</div>
						</Results>
						<PaginationWrapper>
							<Pagination
								onChange={handlePageClick}
								defaultPageSize={10}
								defaultCurrent={currentPage}
								current={currentPage}
								total={
									activeTab === 1
										? pendingRequestCount
										: activeTab === 2
										? pendingRenewalCount
										: totalCount
								}
								//showTotal={(total) => `Total ${total} items`}
							/>
						</PaginationWrapper>
					</HeaderNew>
				) : (
					''
				)}
			</UsersListContainer>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</Container>
	);
};

export default Users;
