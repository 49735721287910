import styled from 'styled-components';

export const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 40px;
`;

export const Text = styled.span`
	font-size: 26px;
	line-height: 39px;
	color: #323643;
	font-weight: 600;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 32% 32% 32%;
	// flex-wrap: wrap;
	// grid-template-columns: 100px 100px 100px;
	// grid-template-rows: 100px auto 100px;
	column-gap: 15px;
	row-gap: 30px;
	margin-top: 20px;
`;

export const MasterGridContainer = styled.div`
	padding: 15px 0px;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
`;
export const CreatePostContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 35px;
`;

export const SearchInput = styled.input`
	padding-left: 35px;
	width: 335px;
	height: 40px;
	cursor: pointer;
	border-radius: 23px;
	border: 1px;
	outline: antiquewhite;
	position:absolute
`;

export const SearchIconImage = styled.img`
	position: relative;
	padding-top: 11px;
	padding-left: 10px;
	`;
export const CloseIconImage = styled.img`
	position: relative;
	left: 280px;
	padding-top: 10.5px;
	width:20px;
`;

export const CreatePostButton = styled.div`
	padding: 12px;
	background-color: #ffdd55;
	border-radius: 26px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-weight:bold;
	font-size:14px;
`;

