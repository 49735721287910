import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {PropTypes,string} from 'prop-types';
// import FocusLock from 'react-focus-lock';
// import styled from 'styled-components';
import {useOnClickOutside} from '../../helpers/Hooks/useOnClickOutside';
import Menu from '../LeftMenu/Menu/Menu';

import {ReactComponent as Open} from '../../assets/images/openBar.svg';
import {useHistory} from 'react-router';
import {AmplifySignOut} from '@aws-amplify/ui-react';
import jwt_decode from 'jwt-decode';
import User from '../../services/users';
import CloseIcon from '../../assets/images/closeIcon.png';
import { Border, Name, NameAndImage, ProfileImage } from './index.styled';
import NewAvatar from '../../assets/images/new-avatar.png';

// const Conatiner = styled.div`
// 	display: flex;
// `;

const Header = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	background-color: #ffd855;
	height: 60px;
	top: 0;
	left: 0;
	z-index: 1;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	margin-right: 20px;
`;

const Container = styled.div`
	padding-top: 60px;
	background-color:#F7F8FB;
`;

const Children = styled.div`
	margin-left: 260px;
	background-color: #F7F8FB;
`;

const Layout = (props) => {

	React.useEffect(()=>{
		let signedInUser = JSON.parse(localStorage.getItem('user'));
		let decodedValue = jwt_decode(
			signedInUser.signInUserSession.accessToken.jwtToken,
		);

		User.getUserById(decodedValue.sub)
			.then((res) => {
				setName(res.data.firstName + ' ' + res.data.lastName);
				if(res.data.profilePic && res.data.profilePic.body){
					setImage(res.data.profilePic.body)
				}

			})
			.catch((err) => {
				console.log('err', err);
				localStorage.clear();
			});
	},[]);

	const [open, setOpen] = useState(true);
	const [name,setName] = useState('');
	const [image,setImage] = useState('');
	const node = useRef();
	const history = useHistory();
	const menuId = 'main-menu';

	useOnClickOutside(node, () => setOpen(true));

	return (
		<Container ref={node}>
			<Header>
				<HeaderContainer>
					<NameAndImage>
						{image !== '' ? (
							<div>
							<ProfileImage
								src={image}
								alt="profile"></ProfileImage>
						</div>
						) : 
						(
							<div>
							<ProfileImage style = {{background: "#B2B3BD",padding:9}}
							src={NewAvatar} alt = "imag"/>
							</div>
						)
						}
						
						<Border></Border>

						<Name>{name}</Name>
					</NameAndImage>
					<div style={{marginRight: 20}}>
						<AmplifySignOut buttonText="Log out" style = {{textTransform: 'lowercase'}} />
					</div>
				</HeaderContainer>
			</Header>
			<Menu open={open} setOpen={setOpen} id={menuId} />
			<Children>{props.children}</Children>
		</Container>
	);
};

Layout.propTypes = {
	children: PropTypes.element
};

export default Layout;
